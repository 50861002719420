import React, { FC, useEffect, useState } from 'react';
import { Flex, useModalContext, useToast, Text } from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useApolloClient, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import omit from 'lodash/omit';
import { useUploadImage, useUserDataSelector } from 'hooks';
import { ActionButton } from '../../../../../ui-components';
import { updateObject } from '../../../../../utils/objectHelper';

import UpdateDocument from './common/UpdateDocument';
import { IDocumentInput } from './common/compliance.input';
import {
  ComplianceResponse,
  FETCH_COMPLIANCE,
  UPDATE_COMPLIANCE,
  UpdateComplianceInput,
  UpdateComplianceResponse,
  MetaComplianceData,
} from './add-document.graphql';
import { Input, toUpdateComplianceInput } from './compliance.helper';
import { eventBus } from '../../../../../shared/eventEmit';
import { getComplianceData } from './compliance-update.helper';
import UploadSkeleton from './UploadSkeleton';
import {
  GET_NEXUS_COMPLIANCES,
  GET_NEXUS_COMPLIANCES_CATEGORY,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';
import UpdatecomplianceFooter from './UpdatecomplianceFooter';
import { AuthRole } from 'sop-commons/src/client';
import { useNotificationUpdate } from '../../Listing/components/common/notificationModal';

interface IProps {
  complianceIds: string[];
  type?: 'update' | 'reminder';
  moveToCompliance?: boolean;
  preDefinedLocationId?: string;
  successCb?: (data: Input | undefined) => void;
  replaceDocument?: boolean;
  disableCategorySelect?: boolean;
  disableDocumentName?: boolean;
  onActionComplete?: () => void;
  uploadedFrom?: 'compliance' | 'location_profile' | 'task_attachment';
  sendForApproval?: boolean;
  disableSigningDate?: boolean;
  disableExpiryDate?: boolean;
  disableReminder?: boolean;
}

const UpdateCompliance: FC<IProps> = ({
  complianceIds,
  type = 'update',
  moveToCompliance,
  preDefinedLocationId,
  successCb,
  replaceDocument,
  disableCategorySelect,
  disableDocumentName,
  onActionComplete,
  uploadedFrom,
  sendForApproval,
  disableExpiryDate,
  disableReminder,
  disableSigningDate,
}) => {
  const { authRole, loggedInUserId } = useUserDataSelector((state) => ({
    authRole: state?.authRole,
    loggedInUserId: state?.eid,
  }));

  const notificationModal = useNotificationUpdate();

  const getNotificationMessage = () => {
    let title = '';
    let description = '';

    if (
      (authRole === AuthRole.LOCATION_OWNER || authRole === AuthRole.ADMIN) &&
      moveToCompliance
    ) {
      title = 'Document moved';
      description =
        'The document will be active in the Compliance tab once approved by the Superadmin. You’ll be notified of the decision. This may take some time.';
    } else if (authRole === AuthRole.SUPER_ADMIN && moveToCompliance) {
      title = 'Document moved';
      description = 'Document successfully moved to compliance tab!';
    } else if (
      (authRole === AuthRole.LOCATION_OWNER || authRole === AuthRole.ADMIN) &&
      replaceDocument
    ) {
      title = 'Document moved';
      description =
        'The document will be active in the Compliance tab once approved by the Superadmin. You’ll be notified of the decision. This may take some time.';
    }
    if (authRole === AuthRole.LOCATION_OWNER || authRole === AuthRole.ADMIN) {
      title = 'Notification updates';
      description =
        'The document will be active in the Compliance tab once approved by the Superadmin. You’ll be notified of the decision. This may take some time.';
    } else {
      title = 'Notification update';
      description =
        'The respective location owners will be notified about the document update. No further action is required.';
    }

    return {
      title,
      description,
    };
  };

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const client = useApolloClient();
  const { onClose } = useModalContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formDataMap, setFormDataMap] = useState<
    Record<string, IDocumentInput>
  >({});
  const [isLoading, setIsLoading] = useState(true);

  const currentComplianceId = complianceIds[currentIndex];
  const methods = useForm<IDocumentInput>();
  const categoryState = useWatch({
    name: 'category',
    control: methods.control,
  });

  // Fetch all compliance data at the start
  useEffect(() => {
    const fetchAllComplianceData = async () => {
      setIsLoading(true);
      try {
        const promises = complianceIds.map(async (id) => {
          const response = await client.query<ComplianceResponse>({
            query: FETCH_COMPLIANCE,
            fetchPolicy: 'network-only',
            variables: { eid: id },
          });
          const complianceData = getComplianceData(
            response.data?.EntityComplianceById,
            moveToCompliance
          );
          if (preDefinedLocationId) {
            complianceData.preDefinedLocationId = preDefinedLocationId;
          }
          return { id, data: complianceData };
        });
        const results = await Promise.all(promises);
        const map = results.reduce((acc, { id, data }) => {
          acc[id] = data;
          return acc;
        }, {} as Record<string, IDocumentInput>);
        setFormDataMap(map);
      } catch (error) {
        console.error('Error fetching compliance data:', error);
        toast({
          status: 'error',
          title: 'Error',
          description: 'Failed to fetch compliance data.',
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllComplianceData();
  }, [complianceIds, client, moveToCompliance, preDefinedLocationId]);

  // Reset form with current compliance data when index or formDataMap changes
  useEffect(() => {
    if (formDataMap[currentComplianceId]) {
      methods.reset(formDataMap[currentComplianceId]);
    }
  }, [currentIndex, formDataMap, currentComplianceId, methods]);

  const [updateCompliance] = useMutation<
    UpdateComplianceResponse,
    UpdateComplianceInput
  >(UPDATE_COMPLIANCE, {
    onCompleted: () => {
      eventBus.emit('refetchCompliance');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Compliance document update failed!',
      });
    },
    refetchQueries: [
      GET_NEXUS_COMPLIANCES,
      'EntityCompliancePagination',
      GET_NEXUS_COMPLIANCES_CATEGORY,
      'EntityComplianceCategories',
    ],
  });

  const uploadImage = useUploadImage();

  // Handle individual submission (for single compliance or non-moveToCompliance cases)
  const onSubmit = async (values: IDocumentInput) => {
    const input = toUpdateComplianceInput(
      cloneDeep(values),
      moveToCompliance,
      sendForApproval
    );
    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file.rawFile);
      const file = omit(values.file, 'rawFile');
      file.url = fileUrl;
      file.signingDate = input.signingDate;
      updateObject(input, ['file'], file);
    }

    if (replaceDocument) {
      input.approvalStatus = 'pending';
      input.status = 'draft';
    }

    await updateCompliance({ variables: { input } });
    toast({
      status: 'success',
      title: 'Success',
      description: moveToCompliance
        ? '1 document have been moved to compliance successfully.'
        : '1 document has been successfully updated.',
    });
    successCb?.(formDataMap);
    setTimeout(onClose);

    const { title, description } = getNotificationMessage();

    if (loggedInUserId !== formDataMap[currentComplianceId]?.createdBy) {
      notificationModal({
        title: title,
        description: description,
      });
    } else {
      if (values?.file?.createdBy) {
        notificationModal({
          title: title,
          description: description,
        });
      }
    }
  };

  // Handle batch update for multiple compliance IDs
  const handleBatchUpdate = async () => {
    setIsLoading(true);
    try {
      const promises = complianceIds.map(async (id) => {
        const values = formDataMap[id];
        const input = toUpdateComplianceInput(
          cloneDeep(values),
          moveToCompliance
        );
        if (values.file?.rawFile) {
          const fileUrl = await uploadImage(values.file.rawFile);
          const file = omit(values.file, 'rawFile');
          file.url = fileUrl;
          updateObject(input, ['file'], file);
        }
        await updateCompliance({ variables: { input } });
      });
      await Promise.all(promises);
      toast({
        status: 'success',
        title: 'Success',
        description: 'All compliance documents successfully updated.',
      });
      successCb?.(formDataMap);
      eventBus.emit('refetchCompliance');
      onClose();
      const { title, description } = getNotificationMessage();

      notificationModal({
        title: title,
        description: description,
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update compliance documents.',
      });
    } finally {
      setIsLoading(false);
    }
    onActionComplete?.();
  };

  // Navigation handlers
  const handleNext = () => {
    const currentValues = methods.getValues();
    setFormDataMap((prev) => ({
      ...prev,
      [currentComplianceId]: currentValues,
    }));
    if (currentIndex < complianceIds.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    const currentValues = methods.getValues();
    setFormDataMap((prev) => ({
      ...prev,
      [currentComplianceId]: currentValues,
    }));
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <Flex flexDir='column' gap={4}>
        <UploadSkeleton isLoading={isLoading}>
          <UpdateDocument
            moveToCompliance={moveToCompliance}
            preDefinedLocationId={preDefinedLocationId}
            type={type}
            complianceId={currentComplianceId}
            replaceDocument={replaceDocument}
            disableCategorySelect={disableCategorySelect}
            disableDocumentName={disableDocumentName}
            disableSigningDate={disableSigningDate}
            disableExpiryDate={disableExpiryDate}
            disableReminder={disableReminder}
          />
        </UploadSkeleton>
        {complianceIds.length > 1 && moveToCompliance ? (
          <UpdatecomplianceFooter
            complianceIds={complianceIds}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleBatchUpdate={handleBatchUpdate}
            currentIndex={currentIndex}
            formDataMap={formDataMap}
            isLoading={isLoading}
            categorySelect={categoryState}
          />
        ) : (
          <ActionButton
            isFullWidth
            colorScheme='blue'
            mt={2}
            actionFn={methods.handleSubmit(onSubmit)}
            isDisabled={!methods.formState.isDirty || isLoading}
          >
            {moveToCompliance
              ? 'Move to Compliance'
              : replaceDocument
              ? 'Replace document'
              : 'Update document'}
          </ActionButton>
        )}
      </Flex>
    </FormProvider>
  );
};

UpdateCompliance.displayName = 'UpdateCompliance';
export default UpdateCompliance;
