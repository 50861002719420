import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Tooltip,
  useModalContext,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { UPDATE_COMPLIANCE } from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';
import { useNotificationUpdate } from 'sub-components/nexus/Compliance/Listing/components/common/notificationModal';
import { ActionButton } from 'ui-components';

interface IProps {
  documentId: string[]; // Changed from string to string[]
  documentName: string[];
  onActionComplete: () => void;
  markAsOther?: boolean;
}

const MoveToOtherContent: FC<IProps> = ({
  documentId,
  documentName,
  onActionComplete,
  markAsOther,
}) => {
  const [moveDocumentToOther, { loading }] = useMutation(UPDATE_COMPLIANCE);
  const { onClose } = useModalContext();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const notificationModal = useNotificationUpdate();

  const handleMoveToOther = async () => {
    try {
      const ids = Array.isArray(documentId) ? documentId : [documentId];
      await moveDocumentToOther({
        variables: {
          input: ids.map((id) => ({
            eid: id,
            type: 'other',
          })),
        },
      });

      toast({
        title: `${documentId.length} document${
          documentId.length > 1 ? 's have' : ' has'
        } been moved to 'Others' successfully.`,
        status: 'success',
      });

      // notificationModal({
      //   title: 'Document moved',
      //   description: `Document(s) moved to 'Others' successfully.`,
      // });

      onActionComplete();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const actionText = markAsOther ? 'mark' : 'move';
  const targetText = markAsOther ? (
    <Box as='span'>
      {' '}
      as an{' '}
      <Box as='span' color='#1A1D1F' fontWeight={600}>
        'Other'
      </Box>{' '}
      location document
    </Box>
  ) : (
    'to Others tab'
  );

  const visibleDocs = documentName.slice(0, 3);
  const remainingCount = documentName.length - visibleDocs.length;
  const allDocs = documentName.join(', ');

  const confirmationText = (
    <Box>
      Are you sure you want to {` ${actionText} `}
      <Box as='span'>
        {visibleDocs.map((name, index) => (
          <Box as='span' color='#1A1D1F' fontWeight={600} key={index}>
            '{name}'{index < visibleDocs.length - 1 && ', '}
          </Box>
        ))}
        {remainingCount > 0 && (
          <Tooltip label={allDocs} aria-label='All documents'>
            <Box
              as='span'
              color='#1A1D1F'
              fontWeight={600}
              cursor='pointer'
              textDecoration='underline'
              textUnderlineOffset='1px'
            >
              {` +${remainingCount} more `}
            </Box>
          </Tooltip>
        )}
      </Box>
      {targetText}?
    </Box>
  );

  return (
    <Flex flexDirection='column'>
      <Flex gap={1} color='#6F767E'>
        {confirmationText}
      </Flex>

      <Flex width='100%' gap={3} marginTop={6}>
        <Button
          onClick={onClose}
          flex={1}
          variant='outline'
          borderRadius='12px'
        >
          Cancel
        </Button>
        <ActionButton
          flex={1}
          colorScheme='blue'
          borderRadius='12px'
          disabled={loading}
          isLoading={loading}
          actionFn={handleMoveToOther}
        >
          Yes
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default MoveToOtherContent;
