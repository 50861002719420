import { Center, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DashboardContainer from 'sub-components/DashboardContainer';
import Loader from 'sub-components/Loader';
import { AttachmentSection, TaskDetailsHeading } from './components';
import DetailsCard from './components/DetailsCard';
import LongTasks from './components/LongTasks';
import { useControl } from './layers';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import { ReactComponent as FormiconIcon } from 'assets/images/formIcon.svg';
import { TaskAttachment } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments';

type TaskDetailsProps = {};

const TaskDetails: FC<TaskDetailsProps> = () => {
  const control = useControl();

  return (
    <DashboardContainer>
      {control?.loading ? (
        <Center h='98vh'>
          <Loader />
        </Center>
      ) : (
        <Flex gap={'16px'} w='full' flexDir='column'>
          <TaskDetailsHeading
            taskData={control?.foundTaskData}
            handleTaskStatusChange={control?.handleTaskStatusChange}
            isTaskLoading={control?.isTaskLoading}
            isAnyStepLoading={control.loadingStepIds.length > 0}
          />
          <Flex gap='16px'>
            <Flex
              flexDir='column'
              gap={4}
              w={'70%'}
              bg={'#ffff'}
              borderRadius={'16px'}
              p={'40px 35px'}
            >
              <LongTasks
                createdAt={control?.createdAt}
                createdBy={control?.createdBy}
                taskData={control?.foundTaskData}
                handleStatusChange={control?.handleStatusChange}
                loadingStepIds={control.loadingStepIds}
                isTaskLoading={control.isTaskLoading}
              />
              {control?.foundTaskData ? (
                <TaskAttachment
                  task={control?.foundTaskData}
                  refetchData={control?.refetchHandler}
                  complianceFiles={
                    control.fileAttachments?.filter(
                      (f) => f.type === 'compliance'
                    ) || []
                  }
                  taskStatus={control?.foundTaskData?.status}
                  otherFiles={
                    control.fileAttachments.filter((f) => f.type === 'other') ||
                    []
                  }
                  locationId={control?.locationId}
                  launchId={control?.launchId}
                />
              ) : null}

              {control?.otherFiles?.length ? (
                <Flex flexDir='column' gap={4}>
                  <BoxHeader color={HeaderColors.Blue} title='Others' />
                  {control?.otherFiles
                    ?.filter(
                      (file) => !control?.attachmentUrls?.includes(file?.url)
                    )
                    ?.map((file, index) => {
                      return (
                        <Flex
                          key={index}
                          gap={1}
                          alignItems='center'
                          onClick={() => {
                            window.open && window.open(file.url);
                          }}
                        >
                          {file.type === 'image' ? (
                            <Image
                              src={file.url}
                              height={25}
                              width={25}
                              style={{ borderRadius: '50%' }}
                            />
                          ) : (
                            <Center
                              boxSize='25px'
                              border='1px solid rgba(111, 118, 126, 1)'
                              borderRadius='50%'
                              p='3px'
                            >
                              <FormiconIcon />
                            </Center>
                          )}
                          <Tooltip hasArrow label={file?.name}>
                            <Text
                              as='span'
                              isTruncated
                              maxW='140px'
                              cursor='pointer'
                            >
                              {file?.name}
                            </Text>
                          </Tooltip>
                        </Flex>
                      );
                    })}
                </Flex>
              ) : null}
            </Flex>
            <Flex w={'30%'} minW='355px'>
              <DetailsCard
                createdAt={control?.createdAt}
                createdBy={control?.createdBy}
                updatedAt={control?.updatedAt}
                taskData={control?.foundTaskData}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </DashboardContainer>
  );
};

TaskDetails.displayName =
  'displayName:sub-components/Launcher/tasks/components/task-details/TaskDetails';

export default TaskDetails;
