import { gql } from '@apollo/client';
import { Activity } from './activity-feed.types';
import { PageInfo } from '../../types';

export interface DescentVariable {
  status: 'pending' | 'active' | 'inactive' | 'revoked';
}

export interface DescentResponse {
  userDescendants: Array<{ name: string }>;
}

export const GET_USER_COUNT = gql`
  query UserDescendants($status: userStatusType) {
    userDescendants(status: $status) {
      name
    }
  }
`;

export interface ActivityVariable {
  perPage: number;
  page: number;
  sort?: '_ID_ASC' | '_ID_DESC' | 'CREATEDAT_ASC' | 'CREATEDAT_DESC';
  filter: Record<string, any>;
}

export interface ActivityResponse {
  userActivity: {
    count: number;
    items: Activity[];
    pageInfo: PageInfo;
  };
}

export const GET_USER_ACTIVITY = gql`
  query UserActivity(
    $page: Int
    $perPage: Int
    $sort: SortFindManyLogInput
    $filter: FilterFindManyLogInput
  ) {
    userActivity(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      count
      items {
        eventName
        meta
        payload
        createdAt
        eid
        user {
          eid
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        hasNextPage
      }
    }
  }
`;
