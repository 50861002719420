import { Flex, Text, Box, Button } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import ApprovalTable from './ApprovalTable';
import { ReactComponent as LightIcon } from 'assets/images/nexus/compliance/light.svg';
import { ReactComponent as Light_blueIcon } from 'assets/images/nexus/compliance/light_blue.svg';
import { ReactComponent as DocumentIcon } from 'assets/images/nexus/compliance/document.svg';
import { ReactComponent as Document_blueIcon } from 'assets/images/nexus/compliance/document_blue.svg';
import { useMarkCompliance } from './useMarkCompliance';
import Loader from 'sub-components/Loader';
import { useNotificationUpdate } from '../notificationModal';
import { useMarkReUpload } from './useMarkReUpload';
import { eventBus } from 'shared/eventEmit';

interface IProps {
  cb?: () => void;
  closeModal: () => void;
  locationId?: string;
}

const PendingApprovalsModal: FC<IProps> = ({ closeModal, locationId, cb }) => {
  const [isMultiSelected, setIsMultiSelected] = useState<boolean>(false);
  const [selectedDocType, setSelectedDocType] = useState<string>('compliance');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedReupload, setSelectedReupload] = useState([]);
  const [rowStatus, setRowStatus] = useState<Map<string, string>>(new Map()); // Track status per row (compliance or other)

  const { approveDocs, loading } = useMarkCompliance();
  const { requestReUpload, loading: reUploadLoading } = useMarkReUpload();
  const notificationModal = useNotificationUpdate();

  const getIconWithColor = (type = 'compliance', color = 'gray') => {
    if (type === 'compliance' && color === 'gray') {
      return <LightIcon />;
    } else if (type === 'compliance' && color === 'blue') {
      return <Light_blueIcon />;
    } else if (type === 'other' && color === 'gray') {
      return <DocumentIcon />;
    } else if (type === 'other' && color === 'blue') {
      return <Document_blueIcon />;
    }
  };

  const handleMarkMultipleDocAsCompliance = () => {
    // Mark the row as compliance in local state
    selectedItems?.map((item) => {
      setRowStatus((prevStatus) =>
        new Map(prevStatus).set(item?.eid, 'compliance')
      );
    });
  };

  // Call approve and reupload apis one after another on final click of 'Confirm' button
  const handleApprovalAndReupload = async (
    complianceIds: string[],
    selectedReupload: { eid: string; comment: string }[],
    closeModal: () => void
  ) => {
    let showToastAndNotifyModal = true;
    try {
      if (complianceIds?.length > 0 && selectedReupload?.length === 0) {
        await approveDocs(complianceIds, closeModal, showToastAndNotifyModal);
        cb?.();
        eventBus.emit('reFetchCount');
      } else if (selectedReupload?.length > 0 && complianceIds?.length === 0) {
        await requestReUpload(
          selectedReupload,
          closeModal,
          showToastAndNotifyModal
        );
        cb?.();
        eventBus.emit('reFetchCount');
      } else {
        await approveDocs(complianceIds);
        await requestReUpload(
          selectedReupload,
          closeModal,
          showToastAndNotifyModal
        );
        cb?.();
        eventBus.emit('reFetchCount');
      }
    } catch (error) {
      console.error('Error processing approval and reupload:', error);
    }
  };

  return (
    <Flex gap={8} flexDir={'column'}>
      <Box color={'#6F767E'}>
        <strong style={{ color: '#33383F' }}>Note:</strong> Rejected documents
        will be automatically deleted, and the concerned member will be notified
        to re-upload the required document.
      </Box>

      <Flex gap={2} flexDir={'column'}>
        {isMultiSelected && selectedItems?.length > 0 && (
          <Flex
            alignItems={'center'}
            fontWeight={600}
            fontSize={'15px'}
            gap={6}
          >
            <Flex
              onClick={() => {
                setSelectedDocType('compliance');
                handleMarkMultipleDocAsCompliance();
              }}
              cursor={'pointer'}
              color={selectedDocType === 'compliance' ? '#2A85FF' : '#6F767E'}
              gap={2}
              alignItems={'center'}
            >
              {getIconWithColor(
                'compliance',
                selectedDocType === 'compliance' ? 'blue' : 'gray'
              )}
              Approve and mark as compliance
            </Flex>
          </Flex>
        )}

        <Flex
          padding={'16px'}
          borderRadius={'12px'}
          border={'2px solid #EFEFEF'}
        >
          <ApprovalTable
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setIsMultiSelected={setIsMultiSelected}
            setRowStatus={setRowStatus}
            rowStatus={rowStatus}
            setSelectedReupload={setSelectedReupload}
            locationId={locationId}
          />
        </Flex>
      </Flex>

      <Flex w='full' align='center' gap={4}>
        <Button
          w='50%'
          size='lg'
          variant='outline'
          bg='white'
          border='2px solid rgba(239, 239, 239, 1)'
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          w='50%'
          size='lg'
          variant='solid'
          colorScheme='blue'
          disabled={rowStatus.size === 0}
          onClick={() => {
            //get eids from checkbox selection
            const multiComplianceId = selectedItems?.map((item) => item?.eid);
            //get eids from clicking on 'Mark as compliance'
            const singleComplianceId = Array.from(rowStatus)
              ?.filter((item) => item?.[1] === 'compliance')
              ?.map((item) => item?.[0]);

            const complianceIds = [
              ...new Set([...multiComplianceId, ...singleComplianceId]),
            ];

            //call api to mark compliance ids as approved
            handleApprovalAndReupload(
              complianceIds,
              selectedReupload,
              closeModal
            );
          }}
        >
          {loading || reUploadLoading ? <Loader /> : 'Confirm'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default PendingApprovalsModal;
