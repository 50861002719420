import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../atoms';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';

import { ReactComponent as BackIcon } from '../../../assets/images/chevronBackward.svg';
import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { usePendingApprovalCount } from 'sub-components/nexus/Compliance/Listing/usePendingApprovalCount';
import { useReSubmissionCount } from 'sub-components/nexus/Compliance/Listing/useReSubmissionCount';
import { IoWarningOutline } from 'react-icons/io5';
import {
  usePendingApprovals,
  useReSubmission,
} from 'sub-components/nexus/Compliance/Listing/components/common';

interface RouteParams {
  locationId: string;
}

const LocationDocumentHeading = ({
  setTriggerRefresh,
  triggerRefresh,
}: {
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  triggerRefresh: boolean;
}) => {
  const { t } = useTranslation(['home']);
  const history = useHistory();
  const { locationId } = useParams<RouteParams>();
  const pendingApprovals = usePendingApprovals();
  const resubmission = useReSubmission();

  const { userAuthRole, locationList, loggedInUserId } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      locationList: state?.entity?.locations,
      loggedInUserId: state?.eid,
    })
  );

  const { reSubmissionCount } = useReSubmissionCount(loggedInUserId);
  const { pendingApprovalCount } = usePendingApprovalCount(locationId);

  const pendingApprovalsCount = pendingApprovalCount;
  const reSubmissionsCount = reSubmissionCount;

  const location = locationList?.find((item) => item?.eid === locationId);

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          <FontAwesomeIcon
            cursor={'pointer'}
            onClick={history.goBack}
            style={{ marginRight: '15px' }}
            icon={faArrowLeft as IconProp}
          />
          {userAuthRole === AuthRole?.LOCATION_OWNER
            ? `${location?.name} Documents`
            : t('home:documentListing')}
        </Text>
        {userAuthRole !== AuthRole?.LOCATION_OWNER && (
          <Button
            fontSize='14px'
            fontWeight={500}
            width='max-content'
            variant='link'
            leftIcon={<BackIcon width='16' height='16' />}
            _hover={{
              textDecoration: 'none',
            }}
            onClick={history.goBack}
          >
            Back to {location?.name}
          </Button>
        )}
      </Flex>

      <Authorize
        permittedFor={'user'}
        permittedRoles={[AuthRole.LOCATION_OWNER, AuthRole.ADMIN]}
      >
        <ActionButton
          height={'44px'}
          borderRadius={'12px'}
          border={'2px solid'}
          borderColor={reSubmissionsCount ? '#EFEFEF' : '#6F767E'}
          disabled={reSubmissionsCount > 0 ? false : true}
          colorScheme={reSubmissionsCount ? 'red' : 'whiteAlpha'}
          color={reSubmissionsCount ? 'white' : '#6F767E'}
          leftIcon={
            <IoWarningOutline
              color={reSubmissionsCount ? 'white' : '#6F767E'}
              fontSize={'18px'}
            />
          }
          actionFn={() => {
            resubmission({ locationId });
          }}
        >
          Re-submission required
        </ActionButton>
      </Authorize>

      <Authorize permittedFor={'user'} permittedRoles={[AuthRole.SUPER_ADMIN]}>
        <ActionButton
          height={'44px'}
          borderRadius={'12px'}
          border={'2px solid'}
          borderColor={pendingApprovalsCount ? '#EFEFEF' : '#6F767E'}
          disabled={pendingApprovalsCount > 0 ? false : true}
          colorScheme={pendingApprovalsCount ? 'red' : 'whiteAlpha'}
          color={pendingApprovalsCount ? 'white' : '#6F767E'}
          leftIcon={
            <IoWarningOutline
              color={pendingApprovalsCount ? 'white' : '#6F767E'}
              fontSize={'18px'}
            />
          }
          actionFn={() => {
            pendingApprovals({
              locationId: locationId,
              cb: () => {
                setTriggerRefresh(!triggerRefresh);
              },
            });
          }}
        >
          View pending approvals
        </ActionButton>
      </Authorize>
    </Flex>
  );
};

export default LocationDocumentHeading;
