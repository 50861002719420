import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import StepDetails from 'pages/TasksDashboard/TaskDetails/components/TaskOverview/StepDetails';
import { IChapterDataResponse } from 'pages/TasksDashboard/TaskDetails/components/TaskOverview/TaskOverviewSteps';
import { FC, useCallback, useState } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import {
  EnumTPContentsType,
  IAnalyticsContainerProps,
  TPPathAnalyticsItem,
} from 'sub-components/training-v2/shared/types';
import { getImage } from 'utils';
import flag from '../../../../../../assets/images/training/flag.svg';
import { IGetTpSessionResponse } from '../query/track-dashboard-graphql';
import { useQuizResponse } from './quiz-response';
import StackedProgressBar from './StackedProgressBar';
import { FormResponseModal } from './form-response';
import moment from 'moment';

interface IProps
  extends Pick<
    IAnalyticsContainerProps,
    'pathAnalytics' | 'trainingId' | 'sessionId'
  > {
  tp: IGetTpSessionResponse | undefined;
}

const PathAnalytics: FC<IProps> = ({
  pathAnalytics,
  sessionId,
  trainingId,
  tp,
}) => {
  const businessData = useReactiveVar(usersEntityObj);
  const [showChapterResModal, setShowChapterResModal] = useState(false);
  const [showFormResModal, setShowFormResModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    TPPathAnalyticsItem | undefined
  >(undefined);
  const quizResponse = useQuizResponse();

  const itemClickHandler = (record: TPPathAnalyticsItem) => {
    if (record?.type === 'quiz') {
      return quizResponse({
        trainingId: trainingId,
        sessionId: sessionId,
        quizId: record?.eid,
        record: {
          ...record,
          createdBy: tp?.TpSessionById?.training?.createdBy,
          createdOn: tp?.TpSessionById?.training?.createdAt,
        },
      });
    } else if (record?.type === 'sop') {
      setSelectedRecord(record);
      setShowChapterResModal(true);
    } else if (record?.type === 'form') {
      setSelectedRecord(record);
      setShowFormResModal(true);
    }
  };

  const getChapterData = useCallback((): IChapterDataResponse[] => {
    if (!tp || !selectedRecord) return [];

    const businessUsers = businessData?.filter((bus) => bus?.type === 'user');

    return tp?.TpSessionById?.assignedUsers
      .filter((user) => {
        const foundUser = businessUsers?.find((u) => u?.eid === user?.userId);
        return foundUser;
      })
      .map((user) => {
        const userProgress = tp?.TpSessionById?.userProgress.find(
          (progress) => progress?.userId === user?.userId
        );

        const sopProgress = userProgress?.progress?.find(
          (p) => p?.id === selectedRecord?.eid
        );

        const foundUser = businessUsers?.find((u) => u?.eid === user?.userId);
        return {
          eid: user?.userId,
          name: foundUser?.name || '',
          profilePic: foundUser?.profilePic || '',
          authRole: foundUser?.authRole || '',
          role: foundUser?.role || '',
          locations: foundUser?.locations || [],
          completed: sopProgress?.isCompleted || false,
          completedDate: moment(sopProgress?.updatedAt)?.isValid()
            ? moment(sopProgress?.updatedAt)?.format('DD MMM YYYY')
            : '-',
        };
      });
  }, [tp, selectedRecord, businessData]);

  const columns: ColumnsType<TPPathAnalyticsItem> = [
    {
      title: (
        <Text fontSize='12px' fontWeight={500} color='#6F767E'>
          Item name
        </Text>
      ),
      dataIndex: 'item',
      key: 'item',
      width: '295px',
      render: (text, record) => (
        <Flex
          align='center'
          gap='16px'
          cursor='pointer'
          onClick={() => itemClickHandler(record)}
        >
          <Image
            src={getImage(record?.thumbnail, record?.title)}
            alt={record.title}
            boxSize='44px'
            objectFit='cover'
            borderRadius='12px'
          />
          <Flex direction='column' gap='1px'>
            <Text
              fontSize='12px'
              fontWeight={500}
              color='#6F767E'
              textTransform='capitalize'
            >
              {EnumTPContentsType[record?.type]}
            </Text>
            <Text fontSize='15px' fontWeight={600} color='#2A85FF' mt='-4px'>
              {record?.title}
            </Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} color='#6F767E'>
          Status
        </Text>
      ),
      dataIndex: 'progress',
      key: 'progress',
      render: (_, record) => (
        <Popover trigger='hover' placement='right'>
          <PopoverTrigger>
            <Box width='150px'>
              <StackedProgressBar
                overDue={record?.progress?.overDue}
                onTime={record?.progress?.onTime}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent w='272px' px='16px' py='12px' borderRadius='8px'>
            <PopoverBody>
              <Flex direction='column' gap='8px'>
                <Text fontWeight={600} fontSize='15px' color='#111315'>
                  {record?.title}
                </Text>
                <Flex align='center' gap={2} justifyContent='space-between'>
                  <Flex gap='8px'>
                    <Box w='12px' h='24px' borderRadius='4px' bg='#B5E4CA' />
                    <Text fontSize='12px' color='#6F767E' fontWeight={500}>
                      On time completion
                    </Text>
                  </Flex>
                  <Text fontSize='12px' fontWeight={600}>
                    {record?.progress?.onTime} members
                  </Text>
                </Flex>
                <Flex align='center' gap={2} justifyContent='space-between'>
                  <Flex gap='8px'>
                    <Box w='12px' h='24px' borderRadius='4px' bg='#FFBC99' />
                    <Text fontSize='12px' color='#6F767E' fontWeight={500}>
                      Yet to start
                    </Text>
                  </Flex>
                  <Text fontSize='12px' fontWeight={600}>
                    {record?.progress?.overDue} members
                  </Text>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ),
    },
  ];

  return (
    <Box width={'1013px'}>
      <Table
        columns={columns}
        dataSource={pathAnalytics}
        pagination={false}
        rowKey='eid'
        rowClassName={(record) =>
          record.type === 'milestone' ? 'milestone-row' : ''
        }
        components={{
          body: {
            row: ({ children, ...props }) => {
              const record = props['data-row-key'];
              const currentRow = pathAnalytics?.find(
                (item) => item.eid === record
              );

              // If it's a milestone, return a full-width custom row
              if (currentRow?.type === 'milestone') {
                return (
                  <tr {...props}>
                    <td colSpan={columns.length} style={{ paddingY: '20px' }}>
                      {/* @ts-ignore */}
                      <Flex
                        w='740px'
                        h='40px'
                        bg='#8E59FF33'
                        borderRadius='12px'
                        align='center'
                        justify='center'
                        ml={1}
                        gap={'10px'}
                        my={2}
                        py={'8px'}
                        px={'12px'}
                      >
                        <Image src={flag} h={'12px'} w={'12px'} />
                        <Text
                          fontSize={'14px'}
                          fontWeight={600}
                          color={'#8E59FF'}
                          isTruncated
                          maxW={'550px'}
                        >
                          {currentRow?.title} completed!
                        </Text>
                      </Flex>
                    </td>
                  </tr>
                );
              }

              return <tr {...props}>{children}</tr>;
            },
          },
        }}
      />
      {showChapterResModal && (
        <StepDetails
          isOpen={showChapterResModal}
          onClose={() => setShowChapterResModal(false)}
          type={'sop'}
          formId=''
          selectedChapterDetail={selectedRecord}
          chapterData={getChapterData()}
          sessionId={sessionId}
        />
      )}
      <FormResponseModal
        isOpen={showFormResModal}
        trainingId={trainingId}
        sessionId={sessionId}
        formId={selectedRecord?.eid!}
        selectedRecord={selectedRecord}
        onClose={() => setShowFormResModal(false)}
      />
    </Box>
  );
};

PathAnalytics.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/PathAnalytics';

export default PathAnalytics;
