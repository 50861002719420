import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Center, Flex, Skeleton, Text, toast } from '@chakra-ui/react';
import { ActionButton } from 'ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ShowStatus from './ShowStatus';
import { ReactComponent as EditDarkIcon } from 'assets/images/edit-dark.svg';
import { useReUpload } from './useReUpload';
import {
  DocumentName,
  Location,
  Source,
  LauncherTaskName,
  DataType,
} from './TableColumns';
import { useLazyQuery } from '@apollo/client';
import { GET_PENDING_APPROVAL_DOC_LIST } from './pendingApproval.query';
import Loader from 'sub-components/Loader';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  selectedItems?: any;
  setSelectedItems?: React.Dispatch<React.SetStateAction<any>>;
  setIsMultiSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setRowStatus: React.Dispatch<React.SetStateAction<any>>;
  rowStatus: any;
  setSelectedReupload?: React.Dispatch<React.SetStateAction<[]>>;
  locationId?: string;
}

const ApprovalTable: FC<IProps> = ({
  setIsMultiSelected,
  selectedItems,
  setSelectedItems,
  setRowStatus,
  rowStatus,
  setSelectedReupload,
  locationId,
}) => {
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  );
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);

  const reuploadModal = useReUpload();

  const getFilterVariables = () => {
    let filterPayload: {
      approvalStatus?: string;
      status?: string[];
      locationIds?: string[];
    } = {
      approvalStatus: 'pending',
      status: ['active'],
    };

    if (locationId) {
      filterPayload.locationIds = [locationId];
    }

    return { filter: filterPayload, perPage: perPage, page: pageNumber };
  };

  const userObject = useReactiveVar(usersEntityObj);

  const checkIfNeedsToDisable = (record) => {
    const complianceIds = selectedItems?.map((item) => item?.eid);
    if (complianceIds?.includes(record?.eid)) {
      return true;
    }
    return false;
  };

  const [getPendingApprovals, { loading: pendingApprovalLoading }] =
    useLazyQuery(GET_PENDING_APPROVAL_DOC_LIST, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setLoading(false);
        if (data.EntityCompliancePagination.items?.length < perPage) {
          setHasMoreData(false);
        }
        if (data?.EntityCompliancePagination?.items?.length > 0) {
          setTableData((prevData) => [
            ...prevData,
            ...data.EntityCompliancePagination.items,
          ]);
        } else {
          setHasMoreData(false);
        }
      },
    });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - scrollTop === clientHeight && !loading && hasMoreData) {
      // Trigger data load when reaching the bottom
      setPageNumber((prevPage) => prevPage + 1);
      setLoading(true);
    }
  };

  useEffect(() => {
    // Initial data fetch when the component mounts
    setLoading(true);
    getPendingApprovals({
      variables: getFilterVariables(),
    });
  }, [locationId, pageNumber]);

  const handleMarkAsCompliance = (eid: string) => {
    // Mark the row as compliance in local state
    setRowStatus((prevStatus) => new Map(prevStatus).set(eid, 'compliance'));
    // approveDocs([eid]); // Call the mutation to mark the doc as compliance
  };

  const handleReUpload = (reUploadData: { eid: string; comment: string }) => {
    if (!reUploadData?.eid || !reUploadData?.comment) {
      console.log('EID and comment are required!');
      return;
    }
    // requestReUpload(reUploadData?.eid, reUploadData?.comment);

    setSelectedReupload?.((prevState) => {
      return [
        ...prevState,
        { eid: reUploadData?.eid, comment: reUploadData?.comment },
      ];
    });

    // Update row status to 'other' when re-upload is requested
    setRowStatus((prevStatus) =>
      new Map(prevStatus).set(reUploadData?.eid, 'reupload')
    );
  };

  const handleEditClick = (eid: string) => {
    setRowStatus((prevStatus) => {
      const newStatus = new Map(prevStatus);
      newStatus.delete(eid);
      return newStatus;
    });

    setSelectedItems?.((prevState) => {
      const newData = prevState?.filter((item) => item?.eid !== eid);
      return newData;
    });

    setSelectedReupload?.((prevState) => {
      const filteredData = prevState?.filter((item) => item?.eid !== eid);
      return filteredData;
    });
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <Text isTruncated color={'#6F767E'} fontWeight={500}>
          Document Name
        </Text>
      ),
      width: '15%',
      dataIndex: 'documentName',
      render: (value, record) => <DocumentName record={record} />,
    },
    {
      title: (
        <Text isTruncated color={'#6F767E'} fontWeight={500}>
          Location
        </Text>
      ),
      width: '15%',
      dataIndex: 'location',
      render: (value, record) => <Location record={record} />,
    },
    {
      title: (
        <Text isTruncated color={'#6F767E'} fontWeight={500}>
          Source
        </Text>
      ),
      width: '17%',
      dataIndex: 'source',
      render: (value, record) => (
        <Source record={record} userObject={userObject} />
      ),
    },
    {
      title: (
        <Text isTruncated color={'#6F767E'} fontWeight={500}>
          Launcher Task Name
        </Text>
      ),
      width: '17%',
      dataIndex: 'launcherTaskName',
      render: (value, record) => <LauncherTaskName record={record} />,
    },
    {
      title: (
        <Text isTruncated color={'#6F767E'} fontWeight={500}>
          Actions
        </Text>
      ),
      dataIndex: '',
      render: (value, record) => {
        const status = rowStatus?.get(record?.eid) || ''; // Get the status for the row (compliance or other)

        return (
          <Flex alignItems={'center'} gap={2}>
            {status === 'compliance' || status === 'reupload' ? (
              <Flex alignItems={'center'} gap={2}>
                <ShowStatus status={status} />
                <EditDarkIcon
                  onClick={() => handleEditClick(record?.eid)}
                  cursor={'pointer'}
                />
              </Flex>
            ) : (
              <>
                <ActionButton
                  isTruncated
                  minWidth={'160px'}
                  fontSize={{
                    base: '10px',
                    md: '11px',
                    lg: '13px',
                  }}
                  borderRadius={'12px'}
                  border={'2px solid #EFEFEF'}
                  color={'#1A1D1F'}
                  colorScheme={'whiteAlpha'}
                  px={'8px'}
                  leftIcon={
                    <FontAwesomeIcon
                      color='#83BF6E'
                      fontSize={'14px'}
                      icon={faCheck as IconProp}
                    />
                  }
                  actionFn={() => {
                    handleMarkAsCompliance(record?.eid);
                  }}
                >
                  Mark as compliance
                </ActionButton>
                <ActionButton

                  minWidth={'160px'}
                  fontSize={{
                    base: '10px',
                    md: '11px',
                    lg: '13px',
                  }}
                  disabled={checkIfNeedsToDisable(record)}
                  borderRadius={'12px'}
                  border={'2px solid #EFEFEF'}
                  color={'#1A1D1F'}
                  colorScheme={'whiteAlpha'}
                  px={'1rem'}
                  leftIcon={
                    <FontAwesomeIcon
                      color='#FF6A55'
                      fontSize={'14px'}
                      icon={faArrowRotateRight as IconProp}
                    />
                  }
                  actionFn={() => {
                    reuploadModal({
                      handleReUpload,
                      record,
                      setSelectedReupload,
                    });
                  }}
                >
                  Request re-upload
                </ActionButton>
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  const rowSelection: TableProps<DataType>['rowSelection'] = {
    selectedRowKeys: selectedItems?.map((item: DataType) => item.eid), // Use IDs from selectedItems
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedItems?.(selectedRows); // Update selected items based on selection
      setIsMultiSelected(selectedRows.length > 0); // Handle multi-selection
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <Flex width={'100%'}>
      {pendingApprovalLoading ? (
        <Flex
          alignItems={'center'}
          height={'300px'}
          justifyContent={'center'}
          width={'100%'}
        >
          {/* <Loader /> */}
          <Flex w='full' justify='space-between' gap={4} wrap='wrap'>
            {[...Array(3)].map((_, index) => (
              <>
                <Skeleton key={index} w='full' height='70px' />
              </>
            ))}
          </Flex>
        </Flex>
      ) : (
        <ConfigProvider
          renderEmpty={() => (
            <Flex
              height={'240px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <EmptyState
                image='Report'
                title={'No Data'}
                description={'No item available to approve or reject'}
              />
            </Flex>
          )}
        >
          <Table<DataType>
            style={{ width: '100%' }}
            rowSelection={{ type: selectionType, ...rowSelection }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 55 * 5 }}
            rowKey={'eid'}
            onScroll={handleScroll} // Add the onScroll handler here
          />
        </ConfigProvider>
      )}
    </Flex>
  );
};

ApprovalTable.displayName =
  'packages/sop-web/src/sub-components/nexus/Compliance/Listing/components/common/pendingApprovals/ApprovalTable';

export default ApprovalTable;
