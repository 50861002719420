import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { AuthRole } from 'sop-commons/src/client';
import { FilterItem } from './Summary/types';
import { NotificationModule } from './types/types';

export const getNotificationTime = (notificationTime: string) => {
  const now = new Date();
  const receivedTime = new Date(notificationTime); // Convert notification time to a Date object
  const difference = now.getTime() - receivedTime.getTime(); // Difference in milliseconds

  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    if (days >= 7) {
      return moment(notificationTime).format('DD MMM YYYY');
    }
    return days === 1 ? `${days}d ago` : `${days}d ago`;
  } else if (hours > 0) {
    return hours === 1 ? `${hours}h ago` : `${hours}h ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? `${minutes}m ago` : `${minutes}m ago`;
  } else {
    return seconds === 1 ? `${seconds}s ago` : `${seconds}s ago`;
  }
};

export const checkFirstTimeFlag = () => {
  const flag = JSON.parse(localStorage.getItem('firstTimeFeatureIntro')!);
  return flag;
};
export const setFirstTimeFlag = (value: boolean) => {
  localStorage.setItem('firstTimeFeatureIntro', JSON.stringify(value));
};

export const removedDomainUrl = (url: string) => {
  const regex = new RegExp('^(https?:\\/\\/)?[^\\/]+');
  const result = url.replace(regex, '');
  return result;
};

export const splitTpParams = (trainingUrl: string) => {
  const parts = trainingUrl?.includes('evp')
    ? trainingUrl?.split('/evp/')?.[1]?.split('/')
    : trainingUrl?.split('/wsv/')?.[1]?.split('/');
  const result: {
    trainingId: string;
    sessionId: string;
    certificateId?: string;
  } = {
    trainingId: parts?.[0],
    sessionId: parts?.[1],
  };
  if (parts?.[2]) {
    result.certificateId = parts[2];
  }
  return {
    trainingId: result.trainingId,
    sessionId: result.sessionId,
    certificateId: result.certificateId,
  };
};

export const filterFilterCopiesByAuthRole = (
  authRole: AuthRole,
  modules: []
): FilterItem[] | NotificationModule[] => {
  // TODO: accept values to filter as well
  const filteredModules = modules.filter(
    (item: FilterItem | NotificationModule) => {
      if (
        authRole === AuthRole.WORKER &&
        ((item as FilterItem | NotificationModule).value === 'audit' ||
          (item as FilterItem | NotificationModule).value === 'location')
      ) {
        return false;
      }
      return true;
    }
  );
  return filteredModules;
};

export const splitComplianceNotications = (url: string) => {
  return url.split('/').slice(0, 3).join('/');
};
