import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { eventBus } from 'shared/eventEmit';

export const GET_REQUIRED_RE_SUBMISSION_COUNT = gql`
  query GetReSubmissionCount($filter: FilterFindManyEntityComplianceInput) {
    EntityCompliancePagination(filter: $filter) {
      count
      items {
        eid
      }
    }
  }
`;

export const useReSubmissionCount = (loggedInUserId) => {
  const [data, setData] = useState(0);
  const [getResubmissionCount, { loading }] = useLazyQuery(
    GET_REQUIRED_RE_SUBMISSION_COUNT,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          approvalStatus: 'rejected',
          createdBy: loggedInUserId,
        },
      },
      onCompleted: (data) => {
        setData(data?.EntityCompliancePagination?.count);
      },
    }
  );

  eventBus.on('refetchCompliance', () => {
    getResubmissionCount();
  });

  useEffect(() => {
    getResubmissionCount();
  }, []);

  return { reSubmissionCount: data };
};
