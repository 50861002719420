import moment from 'moment';
import { ComplianceResponse, ReminderEntity } from './add-document.graphql';
import { IDocumentInput } from './common/compliance.input';

const getReminder = (values: ReminderEntity): IDocumentInput['reminder'] => {
  const R = values?.remindType;

  return {
    ...values,
    reminderType: R ? (R === 'date' ? 'on' : 'before') : (undefined as never),
  };
};

export const getComplianceData = (
  values: ComplianceResponse['EntityComplianceById'],
  moveToCompliance?: boolean
): IDocumentInput => {
  const data = {
    eid: values.eid,
    createdBy: values?.createdBy,
    title: values.title,
    file: values.files?.at(-1),
    reminder: getReminder(values.reminder),
    expiryDate:
      values.expiryDate && moment(values.expiryDate)?.isValid()
        ? moment(values.expiryDate).toDate()
        : undefined,
    signingDate:
      values.signingDate && moment(values.signingDate)?.isValid()
        ? moment(values.signingDate).toDate()
        : undefined,
  } as IDocumentInput;

  if (values?.category) {
    data.category = {
      value: values?.category?.eid,
      label: values?.category?.category,
    };
  }
  if (values?.location) {
    data.location = {
      value: values?.location?.eid,
      label: values?.location?.name,
    };
  }

  const moveToComplianceData = {
    eid: values.eid,
    title: values.title,
    file: values.files?.at(-1),
    reminder: undefined,
    expiryDate: undefined,
    signingDate: undefined,
    location: {},
  };

  if (moveToCompliance) {
    return moveToComplianceData;
  }

  return data;
};
