import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GET_PENDING_APPROVAL_COUNT } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { eventBus } from 'shared/eventEmit';

export const usePendingApprovalCount = (locationId?: string) => {
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);

  const getFilterVariables = () => {
    let filterPayload: {
      approvalStatus?: string;
      status?: string[];
      locationIds?: string[];
    } = {
      approvalStatus: 'pending',
      status: ['active'],
    };

    if (locationId) {
      filterPayload.locationIds = [locationId];
    }

    return filterPayload;
  };

  const [getPendingApprovalCount, { loading }] = useLazyQuery(
    GET_PENDING_APPROVAL_COUNT,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: getFilterVariables(),
      },
      onCompleted: (data) => {
        setPendingApprovalCount(data?.EntityCompliancePagination?.count);
      },
    }
  );

  eventBus.on('reFetchCount', () => {
    getPendingApprovalCount();
  });

  useEffect(() => {
    getPendingApprovalCount();
  }, []);

  return { pendingApprovalCount: pendingApprovalCount };
};
