import { DownloadIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFile, faFilePdf, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { faCode } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'antd';
import certificateImg from 'assets/images/training-path-v2/certificate.svg';
import { HeaderColors } from 'configs';
import { FC, useState, useEffect } from 'react';
import { useDownloadCertificate } from 'sub-components/training-v2/shared/download-certificate';
import { BoxHeader } from 'ui-components';
import { FILENAME_REGEX } from 'utils/constants';

const getFileType = (fileUrl: string) => {
  const extension = fileUrl.split('.').pop()?.toLowerCase();
  if (!extension) return 'other';

  if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) {
    return 'image';
  } else if (['mp4', 'webm', 'mov', 'avi'].includes(extension)) {
    return 'video';
  } else if (['pdf'].includes(extension)) {
    return 'pdf';
  } else if (['doc', 'docx', 'txt', 'rtf'].includes(extension)) {
    return 'document';
  } else if (
    ['js', 'jsx', 'ts', 'tsx', 'html', 'css', 'json'].includes(extension)
  ) {
    return 'code';
  } else {
    return 'other';
  }
};

const FilePreview = ({ file }: { file: string }) => {
  const fileType = getFileType(file);
  const fileName = file?.split(FILENAME_REGEX).pop() || '';

  return (
    <Flex
      p='8px 12px'
      borderRadius='8px'
      border='.5px solid rgba(111, 118, 126, 1)'
      alignItems='center'
      gap={2}
      mr={2}
      mb={2}
      cursor='pointer'
      onClick={() => window.open(file, '_blank')}
    >
      {fileType === 'image' ? (
        <Image
          src={file}
          alt={fileName}
          boxSize='24px'
          objectFit='cover'
          borderRadius='4px'
        />
      ) : fileType === 'video' ? (
        <FontAwesomeIcon icon={faVideo as IconProp} color='#F97066' />
      ) : fileType === 'pdf' ? (
        <FontAwesomeIcon icon={faFilePdf as IconProp} color='#E94D35' />
      ) : fileType === 'document' ? (
        <FontAwesomeIcon icon={faFile as IconProp} color='#3E7BFA' />
      ) : fileType === 'code' ? (
        <FontAwesomeIcon icon={faCode as IconProp} color='#6366F1' />
      ) : (
        <FontAwesomeIcon icon={faFile as IconProp} color='#6F767E' />
      )}
      <Text fontSize='12px' textDecor='underline'>
        {fileName}
      </Text>
    </Flex>
  );
};

interface IProps {
  maxScore: number;
  skillVerifierResponse: {
    rating: number;
    name?: string;
    comment?: string;
    files?: string[];
  }[];
  hasSkillVerifier: boolean;
  quizScore: number;
  certificate: string;
  sessionId: string;
  isTpSessionCompleted: boolean;
  isEvaluationCompleted: boolean;
  isCertificateDownloaded: boolean;
  hideCertificateInfo?: boolean;
  hideProgressCircular?: boolean;
  showFeedback?: boolean;
  hideQuiz?: boolean;
  evaluationSummaryFontSize?: string;
}

const ViewProgressEvaluationSummary: FC<IProps> = ({
  hasSkillVerifier,
  maxScore = 0,
  skillVerifierResponse = [],
  quizScore = 0,
  certificate,
  sessionId,
  isTpSessionCompleted,
  isEvaluationCompleted,
  isCertificateDownloaded,
  hideCertificateInfo = false,
  hideProgressCircular = false,
  showFeedback = false,
  hideQuiz = false,
  evaluationSummaryFontSize,
}) => {
  const { colors } = useTheme();

  const downloadCertificate = useDownloadCertificate();
  const [isCertificateDownloadedClicked, setIsCertificateDownloadedClicked] =
    useState(false);

  const skillVerifierScore = () => {
    const total = skillVerifierResponse
      ?.map((item) => item?.rating)
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const totalScore = () => {
    if (maxScore === 0) {
      // No quiz, only skill verifier score
      return hasSkillVerifier ? skillVerifierScore() : 0;
    }

    if (!hasSkillVerifier) return Number(Number(quizScore).toFixed(2));

    // Average of quiz score and skill verifier score
    const skillVerifierScoreScaled = skillVerifierScore();
    return quizScore + skillVerifierScoreScaled;
  };

  const totalScorePercentage = () => {
    return totalMaxScore === 0
      ? '0'
      : ((totalScore() / totalMaxScore) * 100).toFixed(1);
  };

  useEffect(() => {
    setIsCertificateDownloadedClicked(false);
  }, [sessionId]);

  const totalMaxScore = maxScore + skillVerifierResponse?.length * 10;

  return (
    <Flex flexDir='column' gap={4} w='full'>
      <Box
        mt={4}
        padding='20px 24px'
        border='1px solid #EFEFEF'
        borderRadius='12px'
      >
        <Box
          as='span'
          color='#272B30'
          fontWeight={600}
          fontSize={evaluationSummaryFontSize || '15px'}
        >
          Evaluation summary
        </Box>

        {maxScore > 0 && !hideQuiz && (
          <Flex justifyContent='space-between' marginTop={6}>
            <BoxHeader
              title='Quiz score'
              color={HeaderColors.Blue}
              fontSize='14px'
              fontWeight={500}
            />

            <Flex alignItems='center' fontSize='13px' gap='2px'>
              {Number(quizScore).toFixed(2)}
              <Box color='#6F767E'>/{maxScore}</Box>
            </Flex>
          </Flex>
        )}
        {hasSkillVerifier && (
          <Flex justifyContent='space-between' marginTop={6}>
            <BoxHeader
              title='Skill verifier score'
              color={HeaderColors.Purple}
              fontSize='14px'
              fontWeight={500}
            />
            <Flex alignItems='center' fontSize='13px' gap='2px'>
              {skillVerifierScore() || '-'}{' '}
              <Box color='#6F767E'>/{skillVerifierResponse?.length * 10}</Box>
            </Flex>
          </Flex>
        )}

        {maxScore > 0 && !hideQuiz && (
          <Divider borderColor='gray.200' marginTop={5} />
        )}

        <Flex justify='space-between' align='center' pt={6}>
          {hideQuiz ? (
            <BoxHeader
              title='Total Training Path Score'
              color={HeaderColors.Blue}
            />
          ) : (
            <Text fontWeight='semibold'>Total Training Path Score</Text>
          )}

          <Flex alignItems='center' gap='2px'>
            <Text
              as='span'
              fontSize='20px'
              fontWeight='bold'
              color={colors.blue[500]}
            >
              {totalScore().toFixed(2)}
            </Text>
            <Box fontSize='13px' fontWeight={500} color='#6F767E'>
              /{totalMaxScore}
            </Box>
          </Flex>
        </Flex>

        {!hideProgressCircular && (
          <Flex direction='column' align='center' mt={6} justify={'center'}>
            <Progress
              type='circle'
              percent={totalScorePercentage()}
              size={90}
              strokeColor={totalScorePercentage() > 50 ? '#83BF6E' : '#FF6A55'}
              trailColor='#EFEFEF'
              strokeWidth={10}
              format={(percent) => (
                <Box as='span' color='#111315' fontSize='10px'>
                  {percent}%
                </Box>
              )}
            />

            <Badge
              bg={totalScorePercentage() > 50 ? '#83BF6E' : '#FF6A55'}
              color='white'
              px={3}
              py={1}
              borderRadius='6px'
              marginTop='8px'
            >
              {totalScorePercentage() > 50 ? 'Amazing' : 'Poor'}
            </Badge>
          </Flex>
        )}

        {isTpSessionCompleted && !hideCertificateInfo && (
          <Flex
            border='2px solid #2A85FF'
            padding='8px 20px'
            borderRadius='12px'
            background='#eaf2fe'
            marginTop={6}
            justifyContent='space-between'
            alignItems='center'
          >
            <Flex
              flexDirection='column'
              alignItems='flex-start'
              gap={1}
              padding='8px'
            >
              {isCertificateDownloaded || isCertificateDownloadedClicked ? (
                <Text
                  as='span'
                  color='#33383F'
                  fontWeight={600}
                  fontSize='16px'
                >
                  Your certificate has been successfully downloaded
                </Text>
              ) : (
                <>
                  {hasSkillVerifier && !isEvaluationCompleted ? (
                    <Text
                      as='span'
                      color='#33383F'
                      fontWeight={600}
                      fontSize='16px'
                    >
                      Your certificate will be available for download after the
                      evaluation is complete.
                    </Text>
                  ) : (
                    <>
                      <Text
                        as='span'
                        color='#33383F'
                        fontWeight={600}
                        fontSize='16px'
                      >
                        Your certificate is ready!
                      </Text>
                      <Button
                        variant='unstyled'
                        color='#2A85FF'
                        padding='0px'
                        height='fit-content'
                        display='flex'
                        gap={2}
                        onClick={() => {
                          downloadCertificate({
                            certificateId: certificate,
                            tpSessionId: sessionId,
                            isCertificateDownloaded: isCertificateDownloaded,
                          });
                          setIsCertificateDownloadedClicked(true);
                        }}
                      >
                        Download
                        <DownloadIcon />
                      </Button>
                    </>
                  )}
                </>
              )}
            </Flex>
            <Image
              src={certificateImg}
              alt='certificate'
              width='72px'
              height='auto'
            />
          </Flex>
        )}
      </Box>
      {showFeedback && (
        <Flex flexDir='column' gap={4}>
          {skillVerifierResponse?.some(
            (response) =>
              (response?.comment && response.comment.trim() !== '') ||
              (response?.files &&
                response.files.length > 0 &&
                response.files.some((file) => file && file.trim() !== ''))
          ) && (
            <Box
              padding='20px 24px'
              border='1px solid #EFEFEF'
              borderRadius='12px'
              w='full'
            >
              <Box color='#272B30' fontWeight={600} fontSize='24px' mb={4}>
                Feedback
              </Box>
              <Flex w='full'>
                {skillVerifierResponse?.map((response) => {
                  return (
                    <Flex w='full' flexDir='column' gap={2}>
                      {response?.comment && response.comment.trim() !== '' && (
                        <Box
                          p='8px 20px'
                          borderRadius='12px'
                          bg='white'
                          border='0.5px solid rgba(111, 118, 126, 1)'
                          w='full'
                          color='rgba(111, 118, 126, 1)'
                          fontWeight={500}
                          fontSize='12px'
                        >
                          {response?.comment}
                        </Box>
                      )}
                      {response?.files && response?.files?.length > 0 && (
                        <Flex flexWrap='wrap'>
                          {response?.files?.map(
                            (file, fileIdx) =>
                              file && <FilePreview key={fileIdx} file={file} />
                          )}
                        </Flex>
                      )}
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
          )}
          <Flex direction='column' align='center' mt={2} justify={'center'}>
            <Progress
              type='circle'
              percent={totalScorePercentage()}
              size={70}
              strokeColor={totalScorePercentage() > 50 ? '#83BF6E' : '#FF6A55'}
              trailColor='#EFEFEF'
              strokeWidth={10}
              format={(percent) => (
                <Box as='span' color='#111315' fontSize='10px'>
                  {percent}%
                </Box>
              )}
            />

            <Badge
              bg={totalScorePercentage() > 50 ? '#83BF6E' : '#FF6A55'}
              color='white'
              px={3}
              py={1}
              borderRadius='6px'
              marginTop='8px'
            >
              {totalScorePercentage() > 50 ? 'Amazing' : 'Poor'}
            </Badge>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

ViewProgressEvaluationSummary.displayName =
  'displayName:sub-components/training-v2/play/components/ProgressSection/ViewProgress/ViewProgressEvaluationSummary.tsx';

export default ViewProgressEvaluationSummary;
