import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { GET_COMPLIANCE_CATEGORIES } from '../query/compliance.graphql';

import { ConfigProvider, Table } from 'antd';
import { TableProps } from 'antd/lib';
import otherDoc from 'assets/images/compliance/otherDocGray.svg';
import { SearchInput } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { SortType } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/SortPopover';
import { useMoveToOther } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/moveToOther';
import {
  FiltersChipContainer,
  useDocumentFilter,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters';
import { IDocumentPagination } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/FiltersAndListing';
import {
  GetComplianceDocByIdResponse,
  GET_COMPLIANCE_DOC_BY_ID,
  GET_DOCUMENTS_FOR_LISTING,
  NexusComplianceCategoryResponseType,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';
import { LuDownload } from 'react-icons/lu';
import Loader from 'sub-components/Loader';
import { useDebounce, useUserDataSelector } from '../../../../hooks';
import CategoryListingContainer from './CategoryListingContainer';
import { DefaultTableEmptyState } from './components';
import { useCategoryExportDoc } from './components/exportDocuments';
import useComplianceDocumentColumns from './useComplianceDocumentColumns';
import { ExportDocuments } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import { useComplianceUpload } from '../Create/components';
import { useHistory } from 'react-router-dom';
import { useComplianceNavigateParams } from './useComplianceNavigateParams';
const blueFilter =
  'invert(38%) sepia(89%) saturate(1356%) hue-rotate(199deg) brightness(99%) contrast(104%)';

const ComplianceListContainer: FC = () => {
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1);
  const [categoryId, setCategoryId] = useState<string>('');

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [sorting, setSorting] = useState<SortType | undefined>(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState<string[]>(
    []
  );

  //fetch the documentId from the url to fetch doc-data
  const { documentId } = useComplianceNavigateParams();
  const documentIdPersistantRef = React.useRef<string | null>(null);
  const complianceListContainerRef = React.useRef<HTMLDivElement | null>(null);
  const updateCompliance = useComplianceUpload();

  const history = useHistory();

  const { userAuthRole, loggedInUserLocations, entityId, loggedInUserId } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      entityId: state.entity.eid,
      loggedInUserId: state.eid,
    }));
  const loggedInUserLocationIds = loggedInUserLocations?.map(
    (item) => item?.eid
  );

  const debouncedSearchHandler = useDebounce((value: string) => {
    setDebouncedSearch(value);
  }, 400);

  const onActionComplete = () => {
    let filterPayload: {
      query?: string;
      createdBy?: string[];
      locationIds?: string[];
      expiryDate?: Date;
      categoryId?: string;
    } = {};

    if (documentFilterState.expiredDocumentOnly) {
      filterPayload.expiryDate = new Date();
    }

    if (documentFilterState.uploadedBy.length > 0) {
      filterPayload.createdBy = documentFilterState.uploadedBy;
    }

    if (documentFilterState.locations.length > 0) {
      filterPayload.locationIds = documentFilterState.locations;
    } else if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filterPayload.locationIds = loggedInUserLocationIds;
    }

    if (categoryId) {
      filterPayload.categoryId = categoryId;
    }

    refetchComplianceListing?.({
      variables: {
        page: selectedPageIndex,
        perPage: 10,
        filter: {
          type: 'compliance',
          approvalStatus: 'approved',
          query: debouncedSearch || '',
          ...filterPayload,
        },
        sort: sorting ? sorting : 'CREATEDAT_DESC',
      },
    });

    setSelectedPageIndex(1);
    getComplianceCategories();
    setSelectedRowKeys([]);
  };

  const { columns } = useComplianceDocumentColumns({
    onActionComplete: onActionComplete,
    setSorting: setSorting,
    sorting: sorting,
    loggedInUserLocationIds: loggedInUserLocationIds,
  });

  const {
    FilterComponent,
    documentFilterState,
    hasSelectedFilters,
    setDocumentFilterState,
  } = useDocumentFilter({
    filters: ['Expired', 'uploadedBy', 'location'],
  });

  const exportCategoryDoc = useCategoryExportDoc();
  const moveToOther = useMoveToOther();

  //populate the documentIdPersistantRef with the documentId from the url
  React.useEffect(() => {
    if (documentId) {
      documentIdPersistantRef.current = documentId;
    }
  }, []);
  const { data: updateComplianceDoc } = useQuery<
    GetComplianceDocByIdResponse,
    { eid: string }
  >(GET_COMPLIANCE_DOC_BY_ID, {
    skip: !documentIdPersistantRef.current,
    fetchPolicy: 'network-only',
    variables: documentIdPersistantRef.current
      ? {
          eid: documentIdPersistantRef.current,
        }
      : undefined,
  });
  const [
    getComplianceCategories,
    {
      loading: complianceCategoryLoader,
      data: complianceCategoryData,
      refetch: refetchComplianceCategory,
    },
  ] = useLazyQuery<NexusComplianceCategoryResponseType>(
    GET_COMPLIANCE_CATEGORIES,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [
    getComplianceListing,
    {
      loading: complianceLoader,
      data: complianceData,
      refetch: refetchComplianceListing,
    },
  ] = useLazyQuery<IDocumentPagination>(GET_DOCUMENTS_FOR_LISTING, {
    fetchPolicy: 'network-only',
  });

  const handleExportDocument = ({ isCategorizedExport = false }) => {
    if (isCategorizedExport) {
      exportCategoryDoc({
        categories: complianceCategoryData?.EntityComplianceCategories,
      });
    }
  };

  const rowSelection: TableProps['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  const handleUpdatePopupOnNotificationRedirection = () => {
    if (
      complianceListContainerRef?.current &&
      documentIdPersistantRef?.current
    ) {
      const isRestricted =
        (userAuthRole === AuthRole.LOCATION_OWNER ||
          userAuthRole === AuthRole.ADMIN) &&
        loggedInUserId !== updateComplianceDoc?.EntityComplianceById?.createdBy;
      updateCompliance({
        complianceId: [documentIdPersistantRef?.current],
        preDefinedLocationId:
          updateComplianceDoc?.EntityComplianceById?.locationId,
        onActionComplete: onActionComplete,
        ...(isRestricted && {
          disableCategorySelect: true,
          disableDocumentName: true,
          disableSigningDate: true,
          disableExpiryDate: true,
          disableReminder: true,
          sendForApproval: true,
        }),
      });
    }
  };
  //effect to trigger the update-document-modal when redirected from the expiry-reminder-notification
  useEffect(() => {
    if (
      !documentIdPersistantRef.current ||
      updateComplianceDoc?.EntityComplianceById
    )
      return;
    handleUpdatePopupOnNotificationRedirection();
    history.replace('/nexus/documents');
  }, []);

  useEffect(() => {
    let filterPayload: {
      query?: string;
      createdBy?: string[];
      locationIds?: string[];
      expiryDate?: Date;
      categoryId?: string;
    } = {};

    if (documentFilterState.expiredDocumentOnly) {
      filterPayload.expiryDate = new Date();
    }

    if (documentFilterState.uploadedBy.length > 0) {
      filterPayload.createdBy = documentFilterState.uploadedBy;
    }

    if (documentFilterState.locations.length > 0) {
      filterPayload.locationIds = documentFilterState.locations;
    } else if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filterPayload.locationIds = loggedInUserLocationIds;
    }

    if (categoryId) {
      filterPayload.categoryId = categoryId;
    }

    getComplianceListing({
      variables: {
        page: selectedPageIndex,
        perPage: 10,
        filter: {
          type: 'compliance',
          approvalStatus: 'approved',
          query: debouncedSearch || '',
          ...filterPayload,
          status: ['active'],
        },
        sort: sorting ? sorting : 'CREATEDAT_DESC',
      },
    });
    getComplianceCategories();
    setSelectedRowKeys([]);
  }, [
    documentFilterState,
    debouncedSearch,
    selectedPageIndex,
    categoryId,
    sorting,
  ]);

  useEffect(() => {
    setSelectedPageIndex(1);
  }, [documentFilterState, debouncedSearch, sorting, categoryId]);

  useEffect(() => {
    if (!complianceData?.EntityCompliancePagination) return;

    setTotalRowsCount(complianceData?.EntityCompliancePagination?.count);
  }, [complianceData?.EntityCompliancePagination]);

  useEffect(() => {
    if (complianceData?.EntityCompliancePagination) {
      setSelectedDocumentName(
        complianceData?.EntityCompliancePagination?.items
          ?.filter((item) => selectedRowKeys.includes(item?.eid))
          ?.map((item) => item?.title)
      );
    }
  }, [selectedRowKeys]);

  return (
    <>
      <Flex gap={6} flexDir='column' ref={complianceListContainerRef}>
        <Flex justifyContent='space-between' gap={4} alignItems='center'>
          <SearchInput
            placeholder='Search by document name'
            size='lg'
            value={search}
            hideShortcuts
            onChange={(e) => {
              setSearch(e.target.value);
              debouncedSearchHandler(e.target.value);
            }}
          />

          <Flex gap={2}>
            {/* Export compliance documents */}

            {complianceData &&
              complianceData?.EntityCompliancePagination?.items?.length > 0 && (
                <>
                  <Authorize
                    permittedFor='user'
                    permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
                  >
                    <Flex
                      cursor='pointer'
                      justifyContent={'center'}
                      alignItems={'center'}
                      border={'2px solid #EFEFEF'}
                      borderRadius={'12px'}
                      height={'46px'}
                      width={'46px'}
                      onClick={() => {
                        handleExportDocument({ isCategorizedExport: true });
                      }}
                    >
                      <LuDownload style={{ fontSize: '20px' }} />
                    </Flex>
                  </Authorize>

                  <Authorize
                    permittedFor='user'
                    permittedRoles={[AuthRole.LOCATION_OWNER]}
                  >
                    <ExportDocuments
                      type='compliance'
                      locationIds={loggedInUserLocationIds}
                    />
                  </Authorize>
                </>
              )}

            {/*  Filter Container */}
            {FilterComponent}
          </Flex>
        </Flex>

        {/*  Filter Chip */}
        {hasSelectedFilters && (
          <FiltersChipContainer
            documentFilterState={documentFilterState}
            setDocumentFilterState={setDocumentFilterState}
          />
        )}

        {(userAuthRole === AuthRole.SUPER_ADMIN ||
          userAuthRole === AuthRole.ADMIN) && (
          <CategoryListingContainer
            complianceCategories={
              complianceCategoryData?.EntityComplianceCategories
            }
            refetchComplianceCategory={refetchComplianceCategory}
            onActionComplete={onActionComplete}
            complianceCategoryLoader={complianceCategoryLoader}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
          />
        )}

        {selectedRowKeys.length > 0 && (
          <Box flex={1}>
            <Button
              textColor={'#2A85FF'}
              fontSize={'15px'}
              fontWeight={600}
              variant='ghost'
              leftIcon={
                <Box>
                  <Image
                    src={otherDoc}
                    alt='other-doc'
                    width='24px'
                    filter={blueFilter}
                  />
                </Box>
              }
              onClick={() => {
                moveToOther({
                  documentId: selectedRowKeys.map(String),
                  onActionComplete: onActionComplete,
                  markAsOther: true,
                  documentName: selectedDocumentName,
                });
              }}
            >
              Mark as 'other' location docs
            </Button>
          </Box>
        )}

        <ConfigProvider
          renderEmpty={() => (
            <>
              <DefaultTableEmptyState
                searchFieldText={debouncedSearch}
                filters={hasSelectedFilters}
                complianceCategoryId={categoryId}
              />
            </>
          )}
        >
          <Table
            rowSelection={
              userAuthRole === AuthRole.SUPER_ADMIN ? rowSelection : undefined
            }
            loading={complianceLoader && { indicator: <Loader /> }}
            columns={columns}
            dataSource={complianceData?.EntityCompliancePagination?.items}
            rowKey={(record) => record.id || record.eid || record.name}
            pagination={{
              total: totalRowsCount,
              showSizeChanger: false,
              current: selectedPageIndex,
              onChange: (page) => {
                setSelectedPageIndex(page);
              },
            }}
          />
        </ConfigProvider>
      </Flex>
    </>
  );
};

ComplianceListContainer.displayName =
  'sub-components/nexus/Compliance/Listing/ComplianceListContainer';

export default ComplianceListContainer;
