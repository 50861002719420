import { Flex, Stack } from '@chakra-ui/react';
import React, { FC } from 'react';
import moment from 'moment';
import { SummaryCard, ProgressCard, AverageTpDetails } from './index';
import { ReactComponent as TimeSpentIcon } from 'assets/images/training/timeSpentIcon.svg';
import { ReactComponent as AverageTimeSpent } from 'assets/images/training/averageCompletionRate.svg';
import {
  ITrackPathSummaryContainer,
  TTPDetails,
} from 'sub-components/training-v2/shared/types';

const TrackPathSummaryContainer: FC<ITrackPathSummaryContainer> = ({
  tpDetails,
  summaryData,
  progressData,
}) => {
  const averageTPDetails = {
    averageScore: tpDetails?.averageScore?.averagePercent,
    meta: [
      {
        value: `${tpDetails?.averageCompletionRate}%`,
        label: 'Average completion rate',
        icon: <AverageTimeSpent />,
      },
      {
        value: `${tpDetails?.averageTimeSpentOnTP}`,
        label: 'Average time spent on path',
        icon: <TimeSpentIcon />,
      },
    ],
  };

  // const summaryDetails = {
  //   createdOn: summaryData.tpSessionCreatedOn,
  //   createdBy: summaryData.tpSessionCreatedBy,
  //   category: summaryData.tpSessionSummaryCategory,
  //   supervisedBy: summaryData.tpSessionSupervisedBy,
  //   frequency: 'Once',
  //   status: 'Live',
  // };

  const progressTracker = {
    totalMembers: progressData.totalMembers,
    progress: [
      { count: progressData.membersYetToStart, status: 'Yet to start' },
      { count: progressData.onGoingMembers, status: 'On going' },
      { count: progressData.completedMembers, status: 'Completed' },
      { count: progressData.overDueMembers, status: 'Overdue' },
    ],
  };
  return (
    <Flex gap={'25px'} w='full'>
      <SummaryCard summaryData={summaryData} />
      <ProgressCard progressData={progressTracker} />
      <AverageTpDetails averageTPData={averageTPDetails} />
    </Flex>
  );
};

TrackPathSummaryContainer.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/components/TrackPathSummaryContainer';

export default TrackPathSummaryContainer;
