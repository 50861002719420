import moment from 'moment';
import React from 'react';

import { UserEntityData } from 'shared/graphql/shared-types';
import { TpCategoryEntity } from 'sub-components/training-v2/create/components/TrainingPathDetailsSection/training-category/tp-category.graphql';
import {
  EnumTPContentsType,
  TCyclesSession,
} from 'sub-components/training-v2/shared/types';
import {
  IGetTpSessionResponse,
  RepeatCycle,
  TAssignedUserItem,
  TContentItem,
  TEnumTPStatus,
  TPSession,
  TUserProgressItem,
} from './query/track-dashboard-graphql';

const calculatePercentage = (total: number, max: number) => {
  if (max === 0) return 0;
  const percentage = (total / max) * 100;
  if (!isFinite(percentage) || isNaN(percentage)) return 0;
  return Math.round(percentage);
};

export const calculateAverageScore = (trainingData: TPSession) => {
  const isWillHaveAvgScoreMetric =
    trainingData?.hasSkillVerifier || trainingData?.hasScoring;
  //willl only have avgTimeSpent IFF skillVerifier or tpScore is present
  if (isWillHaveAvgScoreMetric) {
    const totalScore = trainingData?.assignedUsers?.reduce(
      (sum, user) => sum + user?.score || 0,
      0
    );
    const totalAverageScore =
      trainingData?.assignedUsers?.length > 0
        ? totalScore / trainingData?.assignedUsers?.length
        : 0;
    const maxScore = trainingData?.maxScore;
    const averagePercent = calculatePercentage(totalAverageScore, maxScore);
    return {
      totalAverageScore,
      maxScore,
      averagePercent,
    };
  }
};

export const calculateAverageCompletionRate = (trainingData: TPSession) => {
  const totalUsersCompleted =
    trainingData?.userProgress?.filter(
      (p) => p?.isCompleted && !p?.crossTrained
    )?.length || 0;
  const totalUsers = trainingData?.assignedUsers?.filter(
    (user) => !user?.crossTrained && !user?.isRemoved
  ).length;
  return calculatePercentage(totalUsersCompleted, totalUsers);
};

export const calculateAverageTimeSpentOnPath = (
  trainingData: TPSession
): string | number => {
  if (!trainingData?.assignedUsers?.length) return 0; // No users assigned

  const timeDifferences: number[] = trainingData?.assignedUsers
    ?.map((user) => {
      if (
        user?.startedOn &&
        user?.completedOn &&
        moment(user?.startedOn).isValid() &&
        moment(user?.completedOn).isValid()
      ) {
        const start = moment.utc(user?.startedOn);
        const end = moment.utc(user?.completedOn);
        return end?.diff(start, 'minutes'); // Get time difference in minutes
      }
      return 0;
    })
    ?.filter((diff): diff is number => diff !== null); // Remove null values

  if (!timeDifferences?.length) return 0; // No valid time differences

  const averageTime =
    timeDifferences?.reduce((sum, time) => sum + time, 0) /
    timeDifferences?.length;
  const formattedAverageTime = getFormattedAverageTimeSpent(
    undefined,
    undefined,
    Number(Math.round(averageTime))
  );
  return formattedAverageTime; // Return rounded average in DD HH:MM format
};

// //function to filter out the completed users for new assigneeUserType tp
// export const filterOutCompletedUsersForNewAssigneeUserTypeTP = (
//   assigneeUserType: TAssigneeUserType,
//   assignedUsers: TAssignedUserItem[]
// ): TAssignedUserItem[] => {
//   let filteredAssignedUsers: TAssignedUserItem[] = [];
//   if (assigneeUserType === 'new') {
//     filteredAssignedUsers = assignedUsers?.filter(
//       (user) => user?.status === 'completed'
//     );
//   }
//   return filteredAssignedUsers;
// };

export const calculateProgressData = (
  assignedUsers: TPSession['assignedUsers'],
  tpSession: TPSession,
  currentRepeatingTrainingSessionCycle: TCyclesSession,
  userData: UserEntityData[]
) => {
  let filteredAssignedUsers = assignedUsers
    ?.filter((user) => {
      return userData?.find(
        (userDataItem) => userDataItem?.eid === user?.userId
      );
    })
    .filter(Boolean) //explicit check for deleted/inactive users from the platform
    .filter((user) => !user?.isRemoved); //filter out removed users

  // Logic for overdueMembers for repeating TPs
  const dontShowOverdue =
    tpSession?.training?.isRepeating &&
    tpSession?.training?.deadlineType === 'noDeadline';
  const customDurationOverDueCheck =
    tpSession?.training?.isRepeating &&
    tpSession?.training?.deadlineType === 'duration';

  const totalMembers = filteredAssignedUsers?.length;
  let statusCount = {
    membersYetToStart: 0,
    onGoingMembers: 0,
    completedMembers: 0,
    overDueMembers: 0,
  };

  filteredAssignedUsers?.forEach((user) => {
    let now = moment().utc().startOf('day');
    let userDueDate = moment(user?.dueDate).utc();
    let completedOn = moment(user?.completedOn);
    let startedOn = moment(user?.startedOn);
    let endDate = moment(currentRepeatingTrainingSessionCycle?.endDate);

    const isDueDateValid = userDueDate.isValid();
    const isCompletedOnValid = completedOn.isValid();
    const isStartedOnValid = startedOn.isValid();
    const isEndDateValid = endDate.isValid();

    if (user?.status === 'completed' && isCompletedOnValid) {
      // Count completed members
      return (statusCount['completedMembers'] += 1);
    } else if (
      customDurationOverDueCheck &&
      isEndDateValid &&
      now.isAfter(endDate) &&
      user?.status !== 'completed'
    ) {
      // Logic chaining for TPs with custom durations for repeating TPs
      return (statusCount['overDueMembers'] += 1);
    } else if (
      (user?.status === 'started' || user?.status === 'assigned') &&
      isDueDateValid &&
      now.isAfter(userDueDate)
    ) {
      // Check overdue members for standard deadline-based TPs
      if (dontShowOverdue) {
        return (statusCount['overDueMembers'] = -1);
      } else {
        return (statusCount['overDueMembers'] += 1);
      }
    } else if (
      user?.status === 'started' &&
      isStartedOnValid &&
      !isCompletedOnValid
    ) {
      // Count ongoing members
      return (statusCount['onGoingMembers'] += 1);
    } else {
      // Count members yet to start
      return (statusCount['membersYetToStart'] += 1);
    }
  });

  const {
    membersYetToStart,
    onGoingMembers,
    completedMembers,
    overDueMembers,
  } = statusCount;
  return {
    totalMembers,
    membersYetToStart,
    onGoingMembers,
    completedMembers,
    overDueMembers,
  };
};

type PathStats = Record<string, Record<'onTime' | 'overDue', number>>;
export const calculatePathAnalyticsActionCardMetrics = (
  userData: UserEntityData[],
  assignedUsers: TAssignedUserItem[],
  userProgress: TUserProgressItem[],
  contents: TContentItem[]
): PathStats[] => {
  // Filter out deleted/inactive users from the platform
  const filteredAssignedUsers = assignedUsers?.filter((user) =>
    userData?.some((userDataItem) => userDataItem?.eid === user?.userId)
  );

  const assignedUserIds = filteredAssignedUsers
    ?.filter((user) => !user?.isRemoved)
    .map((user) => user?.userId);

  // Only keep user progress entries for assigned and active users
  const updatedUserProgress = userProgress?.filter((user) =>
    assignedUserIds?.includes(user?.userId)
  );

  return contents.map((content) => {
    const currContentId = content?.eid;

    // Users who have completed the current content
    const currContentCompletedMembers = updatedUserProgress?.filter(
      (progress) =>
        progress?.progress?.some((p) => p.id === currContentId && p.isCompleted)
    );

    // Users who are overdue for the current content
    const overDueMembers =
      filteredAssignedUsers?.filter((user) => !user?.isRemoved)?.length -
      currContentCompletedMembers?.length;

    return {
      [currContentId]: {
        onTime: currContentCompletedMembers?.length,
        overDue: overDueMembers,
      },
    };
  });
};

export const getUserName = (userId: string, users: any[]) => {
  const user = users.find(
    (user) => user?.type === 'user' && user?.eid === userId
  );

  if (user) {
    return {
      userName: user?.name,
      userRole: user?.role,
      profilePic: user?.profilePic,
      eid: user?.eid,
    };
  }

  return { userName: 'Unknown User', userRole: 'Unknown Role' };
};

export const formatTime = (timeInSeconds: number): string => {
  if (isNaN(timeInSeconds) || timeInSeconds < 0) return '-';

  if (timeInSeconds >= 3600) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (timeInSeconds >= 60) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}m ${seconds}s`;
  } else {
    return `${Math.round(timeInSeconds)}s`;
  }
};

export const calcAuthRole = (authRole: string) => {
  return authRole?.split(/(?=[A-Z])/).join(' ');
};

const dayOfWeekMap: { [key: number]: string } = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const mothsoftheYear: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const getOrdinal = (n: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export const getRepeatFrequency = (repeatCycle: RepeatCycle): string => {
  const {
    type,
    days,
    weeks,
    dayOfTheWeek,
    months,
    dayOfTheMonth,
    years,
    monthOfTheYear,
  } = repeatCycle;

  switch (type) {
    case 'EVERY_N_DAYS':
      if (days === 1) {
        return `Every day`;
      }
      if (days !== null && days > 0) {
        return `Every ${days} day${days > 1 ? 's' : ''}`;
      }
      return 'Invalid days frequency';

    case 'EVERY_N_WEEKS':
      if (weeks !== null && weeks > 0) {
        let dayName: string | null = null;
        if (
          typeof dayOfTheWeek === 'number' &&
          dayOfTheWeek >= 0 &&
          dayOfTheWeek <= 7
        ) {
          dayName = dayOfWeekMap[dayOfTheWeek];
        } else if (typeof dayOfTheWeek === 'string') {
          dayName = dayOfTheWeek;
        }

        if (dayName) {
          if (weeks === 1) {
            return `Every week on ${dayName}`;
          }
          return `Every ${weeks} weeks on ${dayName}  `;
        }
      }
      return 'Invalid weeks frequency';

    case 'EVERY_N_MONTHS':
      if (
        months !== null &&
        months > 0 &&
        dayOfTheMonth !== null &&
        dayOfTheMonth > 0
      ) {
        if (months === 1) {
          return `Every month on ${getOrdinal(dayOfTheMonth)} `;
        }
        return `Every ${getOrdinal(months)} months on ${getOrdinal(
          dayOfTheMonth
        )}  `;
      }
      return 'Invalid months frequency';

    case 'EVERY_N_YEARS':
      if (
        years !== null &&
        years > 0 &&
        monthOfTheYear &&
        dayOfTheMonth !== null &&
        dayOfTheMonth > 0
      ) {
        const yearPart = years === 1 ? 'year' : `${years} years`;
        return `Every ${yearPart} on ${
          mothsoftheYear?.[monthOfTheYear]
        }  ${getOrdinal(dayOfTheMonth)}`;
      }
      return 'Invalid years frequency';

    default:
      return 'Unknown frequency type';
  }
};
export const getValidContents = (tpSessionData: IGetTpSessionResponse) => {
  const validContents =
    tpSessionData?.TpSessionById?.contents?.filter(
      (content) =>
        !content.isRemoved &&
        content?.type !== EnumTPContentsType['milestone']?.toLowerCase()
    ) || [];
  return validContents;
};

//calculate memberDetails
export const getMemberDetails = (
  tpSessionData: IGetTpSessionResponse,
  userData: UserEntityData[],
  tpSession: TPSession,
  icons: {
    OpenLocationTypeIcon: React.FC<React.SVGProps<SVGSVGElement>>;
    LauncherLocationIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  }
) => {
  const validContents = getValidContents(tpSessionData);
  const memberDetails = tpSessionData?.TpSessionById?.assignedUsers?.map(
    (user) => {
      let formattedAverageTImeSpent: string = '-';
      const assignedUser = user;

      //_userProgress
      const _userProgress = tpSessionData?.TpSessionById?.userProgress?.find(
        (proUser) => proUser?.userId === assignedUser?.userId
      );

      //find the userProgresCompletedCoun to show only the completed contents even after the TP gets edited
      //for completed userPorgress get the previous progress and remove milestone, exclude the isRemoved flag
      const userProgressCompletedCount = _userProgress?.progress?.filter(
        (proItem) => {
          if (proItem?.isCompleted && proItem?.type !== 'milestone') {
            return proItem;
          }
        }
      )?.length;

      //if completed and has started then show else undefined
      if (
        assignedUser?.completedOn &&
        assignedUser?.startedOn &&
        moment(assignedUser.completedOn).isValid() &&
        moment(assignedUser.startedOn).isValid()
      ) {
        formattedAverageTImeSpent = getFormattedAverageTimeSpent(
          assignedUser?.startedOn,
          assignedUser?.completedOn
        );
      }
      const total =
        assignedUser?.completedOn && assignedUser?.status === 'completed'
          ? userProgressCompletedCount
          : validContents?.length;
      const status =
        assignedUser?.status === 'completed'
          ? 'Completed'
          : assignedUser?.status === 'started' && !assignedUser?.completedOn
          ? 'In Progress'
          : 'Pending';
      const completedProgress =
        status === 'In Progress'
          ? _userProgress?.progress?.filter((proItem) => {
              return validContents?.some(
                (someContent) =>
                  someContent?.eid === proItem?.id && proItem?.isCompleted //for in-progress users item will be completeed but filtered out based on status above
              );
            })?.length
          : status === 'Pending'
          ? 0
          : total;

      const assignedEntityUser = userData?.find(
        (user) => user?.eid === assignedUser?.userId
      );

      //find out the evaluator if any for a assingedUser
      const _evaluatorId = tpSession?.userProgress?.find(
        (progress) => progress?.userId === assignedUser?.userId
      )?.evaluatedBy;
      const _evaluatedBy = tpSession?.supervisors?.find(
        (supervisorId) => supervisorId === _evaluatorId
      );
      const _evaluator = userData?.find(
        (user) => user?.eid === _evaluatedBy
      )?.name;

      if (!assignedEntityUser) {
        return null;
      }

      if (assignedEntityUser) {
        return {
          ...assignedUser,
          assignedOn:
            assignedUser?.assignedOn &&
            moment(assignedUser?.assignedOn)?.isValid()
              ? moment(assignedUser?.assignedOn)?.utc().format('DD MMM`YY')
              : '-',
          name: assignedEntityUser?.name,
          job: assignedEntityUser?.role,
          locations: assignedEntityUser?.locations?.map((loc) => {
            return {
              ...loc,
              locationStatus: assignedEntityUser?.locationStatus,
              locationIcon:
                assignedEntityUser?.locationStatus === 'open'
                  ? icons?.OpenLocationTypeIcon
                  : icons?.LauncherLocationIcon,
            };
          }),
          profilePic: assignedEntityUser?.profilePic
            ? assignedEntityUser?.profilePic
            : '',
          dueDate:
            assignedUser.dueDate && moment(assignedUser?.dueDate)?.isValid()
              ? moment(assignedUser?.dueDate).utc().format('DD MMM`YY')
              : '-',
          trainingPathEndDate:
            tpSessionData?.TpSessionById?.endDate &&
            moment(tpSessionData?.TpSessionById?.endDate)?.isValid()
              ? moment(tpSessionData?.TpSessionById?.endDate)
                  .utc()
                  .format('DD MMM`YY')
              : '-',

          completedProgress,
          total,
          status,
          averageTimeSpent: formattedAverageTImeSpent,
          completedOn:
            assignedUser?.completedOn &&
            moment(assignedUser?.completedOn)?.isValid()
              ? moment(assignedUser?.completedOn).utc().format('DD MMM`YY')
              : '-',
          skillVerifier:
            tpSession?.hasSkillVerifier && tpSession?.skillVerifier,
          // maxScore: tpSession?.maxScore,
          maxScore: _userProgress?.isCompleted
            ? _userProgress?.maxScore
            : tpSession?.maxScore,
          score: _userProgress?.score,
          isRepeating: tpSession?.isRepeating,
          hasScoring: tpSession?.hasScoring,
          scorePercent: assignedUser?.scorePercent,
          isRemoved: assignedUser?.isRemoved,
          ratings: assignedUser?.ratings,
          evaluatedBy: _evaluator,
        };
      }
    }
  );
  return memberDetails?.reverse().filter(Boolean); //always get a newly assigned user at the top and remove the null users
};

type TSummaryData = {
  tpSessionSummaryCategory: string;
  tpSessionCreatedOn: string;
  tpSessionCreatedBy: string;
  tpSessionSupervisedBy: {
    name: string;
    avatar: string;
  }[];
  tpSessionRepeatFrequency: string;
  tpSessionStatus: string;
};
export const calculateSummaryCardData = (
  tpSession: TPSession,
  userData: UserEntityData[],
  categories: TpCategoryEntity[],
  trainingStatus: '' | TEnumTPStatus
): TSummaryData => {
  const tpSessionSummaryCategory =
    categories?.find((cat) => cat?.eid === tpSession?.training?.category)
      ?.name ?? 'Default';
  const tpSessionCreatedOn =
    tpSession?.createdAt && moment(tpSession?.createdAt)?.isValid()
      ? moment(tpSession?.createdAt).format('DD MMM YYYY')
      : '-';
  const tpSessionCreatedBy =
    userData.find((user) => user?.eid === tpSession?.training?.createdBy)
      ?.name ?? 'Guest';
  const tpSessionSupervisedBy =
    tpSession?.supervisors
      ?.map((eid) => userData?.find((user) => user?.eid === eid))
      ?.filter(Boolean)
      ?.reduce((acc, curr) => {
        if (curr) {
          acc.push({
            name: curr?.name,
            avatar: curr?.profilePic,
          });
        }
        return acc;
      }, [] as { name: string; avatar: string }[]) ?? [];

  const tpSessionRepeatFrequency = tpSession?.training?.isRepeating
    ? getRepeatFrequency(tpSession?.training?.repeatCycle)
    : 'Once';

  const tpSessionStatus =
    trainingStatus !== 'TRAINING_TERMINATED' &&
    trainingStatus !== 'TRAINING_DELETED'
      ? 'Live'
      : 'Inactive';
  return {
    tpSessionSummaryCategory,
    tpSessionCreatedOn,
    tpSessionCreatedBy,
    tpSessionSupervisedBy,
    tpSessionRepeatFrequency,
    tpSessionStatus,
  };
};

export const getFormattedAverageTimeSpent = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  calculatedTimeSpent?: number // in minutes
): string => {
  let totalMinutes: number;

  if (typeof calculatedTimeSpent === 'number' && !isNaN(calculatedTimeSpent)) {
    totalMinutes = calculatedTimeSpent;
  } else if (start && end) {
    const duration = moment.duration(moment(end).diff(moment(start)));
    totalMinutes = duration.asMinutes();
  } else {
    return '-';
  }

  const totalDays = Math.floor(totalMinutes / (24 * 60));
  const years = Math.floor(totalDays / 365);
  const months = Math.floor((totalDays % 365) / 30);
  const days = totalDays % 30;
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor(totalMinutes % 60);

  const parts: string[] = [];

  if (years > 0) {
    parts.push(`${years} Year${years !== 1 ? 's' : ''}`);
    const leftoverDays = totalDays % 365;
    if (leftoverDays > 0) {
      parts.push(`${leftoverDays} Day${leftoverDays !== 1 ? 's' : ''}`);
    }
  } else if (months > 0) {
    parts.push(`${months} Mon${months !== 1 ? 's' : ''}`);
    if (days > 0) {
      parts.push(`${days} Day${days !== 1 ? 's' : ''}`);
    }
  } else if (totalDays > 0) {
    parts.push(`${totalDays} Day${totalDays !== 1 ? 's' : ''}`);
    if (hours > 0) {
      parts.push(`${hours} Hr${hours !== 1 ? 's' : ''}`);
    }
  } else {
    if (hours > 0) {
      parts.push(`${hours} Hr${hours !== 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes !== 1 ? 's' : ''}`);
    }
  }

  return parts.length > 0 ? parts.join(' ') : '-';
};
