import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import DashboardContainer from 'sub-components/DashboardContainer';
import ComplianceHeading from './components/ComplianceHeading';
import BaseNexusCompliance from 'sub-components/nexus/Compliance/BaseNexusCompliance';
import { useHistory } from 'react-router-dom';
import { useComplianceNavigateParams } from 'sub-components/nexus/Compliance/Listing';

const NexusCompliance: FC = () => {
  const history = useHistory();
  const { locationId } = useComplianceNavigateParams();

  React.useEffect(() => {
    if (locationId) {
      history.push({
        pathname: `/locations/${locationId}`,
        state: {
          tab: 'docs',
        },
      });
    }
  }, []);

  return (
    <DashboardContainer>
      <ComplianceHeading />
      <Flex direction='column'>
        <Flex mt='20px' columnGap='1rem'>
          <div
            style={{
              width: '100%',
            }}
          >
            <Card
              style={{
                position: 'relative',
                borderRadius: '12px',
              }}
            >
              <BaseNexusCompliance />
            </Card>
          </div>
        </Flex>
      </Flex>
    </DashboardContainer>
  );
};

export default NexusCompliance;
