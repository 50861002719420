import { Box, Flex, Text } from '@chakra-ui/react';
import SortPopover, {
  SortType,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/SortPopover';
import React, { FC } from 'react';
import {
  DocType,
  DocumentName,
  TaskName,
  UploadedBy,
  UploadedOn,
} from './TableComponents';
import { DocumentAction } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import { useUserDataSelector } from 'hooks';

interface IProps {
  onActionComplete: () => void;
  setSorting: (value: SortType | undefined) => void;
  sorting: SortType | undefined;
}

const useLocationComplianceColumns = ({
  onActionComplete,
  setSorting,
  sorting,
}: IProps) => {
  const { loggedInUserTimezone } = useUserDataSelector((state) => ({
    loggedInUserTimezone: state?.timezone,
  }));

  const columns = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document Name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      width: '17%',
      render: (value) => {
        return <DocumentName value={value} />;
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Task Name
        </Text>
      ),
      dataIndex: 'taskDetails',
      key: 'taskDetails',
      width: '17%',
      render: (_, value) => {
        return <TaskName value={value} />;
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document type
        </Text>
      ),
      width: '12%',
      dataIndex: 'documentType',
      key: 'documentType',
      render: (value, row) => {
        return <DocType value={value} row={row} />;
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      width: '17%',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, value) => {
        return <UploadedBy value={value} />;
      },
    },
    {
      title: (
        <Flex gap={2} alignItems={'center'}>
          <Text fontWeight={500} color={'#6F767E'}>
            Uploaded on
          </Text>
          <SortPopover
            sorting={sorting}
            setSorting={setSorting}
            sortKey='CREATEDAT'
          />
        </Flex>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '14%',
      render: (value) => {
        return <UploadedOn value={value} timezone={loggedInUserTimezone} />;
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      width: '5%',
      render: (values, row) => {
        return (
          <Box>
            <DocumentAction
              document={row}
              onActionComplete={onActionComplete}
              pageType='LocationLauncher'
            />
          </Box>
        );
      },
    },
  ];

  return { columns };
};

export default useLocationComplianceColumns;
