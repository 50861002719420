import { gql } from '@apollo/client';
import { IComplianceCategory } from 'types/nexus.types';

export type NexusComplianceResponseType = {
  EntityCompliancePagination: {
    count: number;
    items: {
      eid: string;
    }[];
  };
};
export const GET_NEXUS_COMPLIANCES = gql`
  query EntityCompliancePagination {
    EntityCompliancePagination {
      count
      items {
        eid
      }
    }
  }
`;

export type NexusComplianceCategoryResponseType = {
  EntityComplianceCategories: IComplianceCategory[];
};

export const GET_NEXUS_COMPLIANCES_CATEGORY = gql`
  query EntityComplianceCategories {
    EntityComplianceCategories {
      category
      eid
    }
  }
`;

export const GET_DOCUMENTS_FOR_LISTING = gql`
  query EntityCompliancePagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyEntityComplianceInput
    $sort: SortFindManyEntityComplianceInput
  ) {
    EntityCompliancePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eid
        entityId
        title
        approvalStatus
        type
        createdBy
        expiryDate
        signingDate
        createdAt
        location {
          isRoot
          name
          eid
        }
      }
    }
  }
`;

export interface GetComplianceDocByIdItem {
  files: {
    url: string;
    name: string;
  };
  eid: string;
  locationId: string;
  createdBy: string;
}
export interface GetComplianceDocByIdResponse {
  EntityComplianceById: GetComplianceDocByIdItem;
}
export const GET_COMPLIANCE_DOC_BY_ID = gql`
  query GetComplianceDocbyId($eid: String!) {
    EntityComplianceById(eid: $eid) {
      files {
        url
        name
      }
      eid
      locationId
      createdBy
    }
  }
`;
