import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import { PendingApprovalsModal } from './pendingApprovals';

interface IProps {
  locationId?: string;
  cb?: () => void;
}

type PendingApprovals = (props?: IProps) => void;

export const usePendingApprovals = (): PendingApprovals => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ locationId, cb }: IProps = {}) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between' gap={3}>
          <BoxHeader
            title={'Review and mark as compliance'}
            color={'#CABDFF'}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <PendingApprovalsModal
          closeModal={() => confirmRef.current?.destroy()}
          locationId={locationId}
          cb={cb}
        />
      ),
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '0px',
        borderRadius: '12px',
        minW: { base: '94vw', xl: '92vw' },
      },
      footer: null,
    });
  }, []);
};
