import { useLocation } from 'react-router-dom';

export const useComplianceNavigateParams = () => {
  const { search: searchParams } = useLocation();
  const queryParams = new URLSearchParams(searchParams);
  const locationId = queryParams.get('locationId');
  const documentId = queryParams.get('documentId');
  const navigationAction = queryParams.get('navigationAction');
  return {
    locationId,
    documentId,
    navigationAction,
  };
};
