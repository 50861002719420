import { gql } from '@apollo/client';
import { FileEntity } from '../../../../../types';

interface ComplianceFile extends FileEntity {
  createdBy: string;
}

type RemindChannel = 'email' | 'sms' | 'push';
type RemindType = 'date' | 'days' | 'weeks' | 'months' | 'years';

export interface ReminderEntity {
  remindBefore: number;
  remindChannels: RemindChannel[];
  remindDate: string | Date;
  remindType: RemindType;
  remindUserType: ('self' | 'users')[];
  remindAdmins: string[];
  remindOwners: string[];
}

export interface MetaComplianceData {
  launchId?: string;
  launcherName?: string;
  phase?: string;
  phaseId?: string;
  task?: string;
  taskId?: string;
}

export interface AddComplianceInput {
  input: {
    title: string;
    file: ComplianceFile;
    categoryId: string;
    locationId?: string;
    signingDate?: Date;
    expiryDate?: Date;
    reminder: Partial<ReminderEntity>;
    metadata?: MetaComplianceData;
    type?: 'compliance' | 'other';
  }[];
}

export interface AddComplianceResponse {
  AddEntityCompliance: {
    eid: string;
  };
}

export const ADD_COMPLIANCE = gql`
  mutation AddEntityCompliance($input: [NewComplianceDataInput]) {
    AddEntityCompliance(input: $input) {
      eid
    }
  }
`;

export interface UpdateComplianceInput {
  input: {
    status: string;
    eid: string;
    file?: ComplianceFile;
    categoryId: string;
    locationId?: string;
    expiryDate?: Date;
    signingDate?: Date;
    title?: string;
    reminder: Partial<ReminderEntity>;
    type?: string;
    approvalStatus?: 'compliance' | 'pending' | 'rejected';
  };
}

export interface UpdateComplianceResponse {
  UpdateEntityCompliance: {
    eid: string;
  };
}

export const UPDATE_COMPLIANCE = gql`
  mutation UpdateEntityCompliance($input: [UpdateComplianceDataInput]) {
    UpdateEntityCompliance(input: $input) {
      eid
    }
  }
`;

export interface ComplianceResponse {
  EntityComplianceById: {
    signingDate?: string;
    eid: string;
    createdBy?: string;
    title: string;
    files: ComplianceFile[];

    category?: {
      eid: string;
      category: string;
    };
    location?: {
      eid: string;
      name: string;
    };
    expiryDate?: string;
    reminder?: ReminderEntity;
  };
}

export const FETCH_COMPLIANCE = gql`
  query ComplianceForEdit($eid: String!) {
    EntityComplianceById(eid: $eid) {
      eid
      title
      createdBy
      files {
        name
        url
        mimetype
        type
        fileSize
      }
      category {
        eid
        category
      }
      location {
        eid
        name
      }
      signingDate
      expiryDate
      reminder {
        remindBefore
        remindChannels
        remindDate
        remindType
        remindUserType
        remindAdmins
        remindOwners
      }
    }
  }
`;
