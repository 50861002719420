import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon, ArrowLeftIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../../assets/images/chevronBackward.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from 'atoms';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { HeaderColors } from 'configs';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { useControl } from '../layers';

interface TaskDetailsHeadingProps {
  isTaskLoading: boolean;
  isAnyStepLoading: boolean;
  taskData: PhaseTask | undefined;
  handleTaskStatusChange: () => void;
}

const TaskDetailsHeading: FC<TaskDetailsHeadingProps> = ({
  isTaskLoading,
  isAnyStepLoading,
  taskData,
  handleTaskStatusChange,
}) => {
  const history = useHistory();
  const status = taskData?.status;
  const control = useControl();

  const allStepsCompleted = !!taskData?.steps?.every(
    (step) => step?.completedAt
  );

  const isDisabledFn = () => {
    if (taskData?.completedAt) {
      return isTaskLoading || isAnyStepLoading;
    }

    const locFiles =
      control.fileAttachments?.filter((f) => {
        return f?.status !== 'inactive' && f?.locationId === control.locationId;
      }) || [];

    const complianceFiles =
      locFiles?.filter((f) => f.type === 'compliance') || [];

    const requiredReupload = control?.fileAttachments?.filter(
      (f) =>
        f?.status !== 'inactive' &&
        f.type === 'compliance' &&
        f.approvalStatus === 'rejected'
    )?.length;

    return (
      isTaskLoading ||
      isAnyStepLoading ||
      taskData?.steps?.some((step) => !step?.completedAt) ||
      (taskData?.docRequired && locFiles.length === 0) ||
      requiredReupload ||
      (taskData?.complianceDocRequired && complianceFiles.length === 0)
    );
  };

  return (
    <Flex align='center' justify='space-between'>
      <Flex m={4} alignItems={'flex-start'} gap={2}>
        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              size={'2x'}
              color={'black'}
            />
          }
          _hover={{
            textDecoration: 'none',
          }}
          onClick={history.goBack}
        />

        <Flex flexDir='column' gap={1}>
          <Text
            fontWeight={600}
            fontSize='24px'
            lineHeight={'48px'}
            isTruncated
            maxW='700px'
          >
            {taskData?.title}
          </Text>
          <Text color={'#6F767E'}>Back to tasks</Text>
        </Flex>
      </Flex>
      {status !== 'locked' &&
        status !== 'yetToStart' &&
        status !== 'yetToStart/locked' && (
          <Button
            bg={
              status === 'completed'
                ? HeaderColors.Red.Mid
                : HeaderColors.DarkBlue
            }
            color='white'
            _hover={{
              bg:
                status === 'completed'
                  ? HeaderColors.Red.Mid
                  : HeaderColors.DarkBlue,
            }}
            disabled={isDisabledFn()}
            isLoading={isTaskLoading}
            rightIcon={
              <FontAwesomeIcon
                icon={
                  (status === 'completed' ? faRotateRight : faCheck) as IconProp
                }
              />
            }
            onClick={handleTaskStatusChange}
          >
            {status === 'dueToday' ||
            status === 'inProgress' ||
            status === 'overdue' ||
            status === 'thisWeek'
              ? 'Mark task as completed'
              : 'Mark as unfinished'}
          </Button>
        )}
    </Flex>
  );
};

TaskDetailsHeading.displayName =
  'displayName:sub-components/Launcher/tasks/components/task-details/components/TaskDetailsHeading';

export default TaskDetailsHeading;
