import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { ColumnsType } from 'atoms';
import { AuthRole } from 'authorization';
import moment from 'moment-timezone';
import { DocumentAction } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import SortPopover, {
  SortType,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/SortPopover';
import { IDocument } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/FiltersAndListing';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { DocumentName, LocationName } from './components/component';
import { useUserDataSelector } from 'hooks';
import { getUserTimezoneConvertedDate } from 'pages/launcher/details/helper';

interface IProps {
  onActionComplete: () => void;
  setSorting: (value: SortType | undefined) => void;
  sorting: SortType | undefined;
  loggedInUserLocationIds?: string[];
}

const useComplianceDocumentColumns = ({
  onActionComplete,
  setSorting,
  sorting,
  loggedInUserLocationIds,
}: IProps) => {
  const users = useReactiveVar(usersEntityObj);
  const { userAuthRole, loggedInUserTimezone } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      loggedInUserTimezone: state?.timezone,
    })
  );

  const getUser = (eid: string) => {
    return users.find((user) => user?.eid === eid);
  };

  const showingLocations = () => {
    if (
      userAuthRole === AuthRole.SUPER_ADMIN ||
      userAuthRole === AuthRole.ADMIN ||
      (loggedInUserLocationIds && loggedInUserLocationIds.length > 1)
    ) {
      return true;
    }
    return false;
  };

  const getDocWidth = () => {};

  const columns: ColumnsType = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return (
          <Tooltip label={values?.title}>
            <Text
              isTruncated
              maxWidth={showingLocations() ? '200px' : 'fit-content'}
            >
              {values?.title}
            </Text>
          </Tooltip>
        );
      },
    },
    ...((loggedInUserLocationIds && loggedInUserLocationIds.length > 1) ||
    userAuthRole === AuthRole.SUPER_ADMIN ||
    userAuthRole === AuthRole.ADMIN
      ? [
          {
            title: (
              <Text fontWeight={500} color={'#6F767E'}>
                Location name
              </Text>
            ),
            dataIndex: 'location',
            key: 'location',
            width: '15%',
            render: (_, values) => <LocationName values={values} />,
          },
        ]
      : []),
    {
      title: (
        <Box as='span' fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
          Uploaded by
        </Box>
      ),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: showingLocations() ? '11%' : '16%',
      render: (_, record: IDocument) => {
        const user = getUser(record?.createdBy);

        return (
          <Flex gap='5px' fontSize={'14px'}>
            <Image
              src={getImage(user?.profilePic, user?.name)}
              height='24px'
              width='24px'
              borderRadius='5px'
            />
            <Flex>
              <Tooltip label={user?.name}>
                <Box
                  as='span'
                  isTruncated
                  maxWidth={showingLocations() ? '80px' : '100px'}
                  fontWeight='600'
                  color='#33383F'
                >
                  {user?.name}
                </Box>
              </Tooltip>
              <Tooltip label={user?.role}>
                <Box
                  isTruncated
                  maxWidth={showingLocations() ? '60px' : '100px'}
                  as='span'
                  color='#6F767E'
                  fontWeight={400}
                >
                  , {user?.role}
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Uploaded on
          <SortPopover
            setSorting={setSorting}
            sorting={sorting}
            sortKey='CREATEDAT'
          />
        </Flex>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '13%',
      render: (value) => {
        const convertedDate = getUserTimezoneConvertedDate(
          loggedInUserTimezone,
          value
        );

        return (
          <Flex
            flex={1}
            cursor='pointer'
            flexDirection='column'
            alignItems='flex-end'
          >
            <Box as='span' color='#33383F' fontWeight={600}>
              {value && moment(value)?.isValid()
                ? moment(convertedDate).format('DD MMM YYYY')
                : '--'}
            </Box>
            <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
              {value && moment(value)?.isValid()
                ? moment(convertedDate).format('hh:mm A')
                : '--'}
            </Box>
          </Flex>
        );
      },
    },

    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Signed Date
        </Flex>
      ),
      dataIndex: 'signingDate',
      key: 'signingDate',
      width: showingLocations() ? '11%' : '13%',
      render: (value: Date) => (
        <Flex
          flex={1}
          cursor='pointer'
          flexDirection='column'
          alignItems='flex-end'
        >
          <Box as='span' color='#33383F' fontWeight={600}>
            {value && moment(value)?.isValid()
              ? moment(value).format('DD MMM YYYY')
              : '--'}
          </Box>
        </Flex>
      ),
    },
    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Expiry Date
          <SortPopover
            setSorting={setSorting}
            sorting={sorting}
            sortKey='EXPIRYDATE'
          />
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: 145,
      render: (value: Date) => {
        const isExpired =
          moment(value)?.isValid() && moment(value)?.isBefore(moment()?.utc());

        return (
          <Flex
            flex={1}
            cursor='pointer'
            flexDirection='column'
            alignItems='flex-end'
          >
            <Box
              as='span'
              color={isExpired ? '#FF6A55' : '#33383F'}
              fontWeight={600}
            >
              {value && moment(value)?.isValid()
                ? moment(value).format('DD MMM YYYY')
                : '--'}
            </Box>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      width: '5%',
      render: (_, record) => {
        return (
          <>
            <DocumentAction
              document={record}
              onActionComplete={onActionComplete}
              pageType='NexusCompliance'
            />
          </>
        );
      },
    },
  ];

  return { columns };
};

export default useComplianceDocumentColumns;
